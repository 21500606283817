import {BusinessSettingsStyled} from "./settingsStyles";
import {Heading} from "../../../styles/textStyles";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import {Button, CheckBox, Form, Input, ScreenLoading, Select} from "../../../components";
import {useBusinessSettingMutation} from "../../../hooks/useServices/useBusinessSettingServices";
import {useBusinessByIdQuery} from "../../../hooks/useServices/useStoreServices";
import {useEffect, useState} from "react";
import {FlexStyled} from "../../../styles/utilStyles";
import currency_list from "../../../data/selectField/allCurrencies";
import sellScreenLayouts, {sellScreenLayoutsEnum} from "../../../data/selectField/sellScreenLayouts";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {isDisplayDashboardFinancialRecords, setDisplayDashboardFinancialRecords} from "../../../utils";


const BusinessSettings = () => {
  const {toast} = useGlobalContext()
  const {data: userProfile} = useUserProfile()
  const updateBusinessSettingsMutation = useBusinessSettingMutation()
  const {isSuccess: isBusinessSettingsSuccess, data: business, status, refetch} = useBusinessByIdQuery()
  const {
    auto_payment_confirm,
    credit_sales,
    due_date,
    partial_payment,
    payment_date,
    date_created,
    sell_below_stock,
    tables,
    wip,
    id,
    do_delivery,
    commission,
    exchange_rate,
    subscription_services,
    faulty_products,
    use_sales_margin,
    dashboard_financial_records = isDisplayDashboardFinancialRecords()
  } = business?.settings || {}
  const [isShowExchangeRate, setIsShowExchangeRate] = useState(exchange_rate)
  
  useEffect(() => {
    setIsShowExchangeRate(exchange_rate)
  }, [exchange_rate])
  
  const handleBusinessSettings = (values) => {
    if (values?.use_sales_margin && !values?.sales_margin) {
      toast.error("Please set sales margin percentage or deactivate it")
      return
    }
    
    setDisplayDashboardFinancialRecords(values.dashboard_financial_records)
    updateBusinessSettingsMutation.mutate({id, body: values})
  }
  
  const handleCheckForeignCurrency = (isChecked, fieldName, setFieldValue) => {
    if(isChecked) {
      setIsShowExchangeRate(true)
      return
    }
    
    setIsShowExchangeRate(false)
    setFieldValue("currency_name", "")
    setFieldValue("ex_currency", null)
    setFieldValue("currency_exchange_rate", "")
  }
  
  const getFormValues = (settings) => {
    const businessSettings = {
      ...settings,
      sales_margin: Number(settings?.sales_margin) || "",
      sell_screen_name: sellScreenLayoutsEnum[settings?.sell_screen],
      dashboard_financial_records: isDisplayDashboardFinancialRecords()
    }
  
    if (!businessSettings.ex_currency) {
      return businessSettings
    }
  
    const currency = currency_list.find(curr => curr.code === businessSettings.ex_currency)
    return {currency_name: currency.name, ...businessSettings}
  }

  return (
    <BusinessSettingsStyled>
      <Heading size={1.45} color="black">{userProfile?.[0]?.business_name} Settings</Heading>
  
      {(status === "error") && (
        <ScreenLoading refetchFn={refetch} />
      )}

      {isBusinessSettingsSuccess && (
        <Form values={getFormValues(business?.settings)} onSubmit={handleBusinessSettings}>
          <CheckBox optional name="date_created" checked={date_created} labelProp="Customize date for orders"/>
          <CheckBox optional name="payment_date" checked={payment_date} labelProp="Allow dynamic payment date"/>
          <CheckBox optional name="due_date" checked={due_date} labelProp="Add due date to checkout"/>
          <CheckBox optional name="faulty_products" checked={faulty_products} labelProp="Bad and damage module"/>
          <CheckBox optional name="sell_below_stock" checked={sell_below_stock} labelProp="Sell below stock"/>
          <CheckBox optional name="tables" checked={tables} labelProp="Tables & Kitchen"/>
          <CheckBox optional name="wip" checked={wip} labelProp="Work in progress (Wip)"/>
          <CheckBox optional name="credit_sales" checked={credit_sales} labelProp="Allow credit sales"/>
          <CheckBox optional name="partial_payment" checked={partial_payment} labelProp="Allow partial payment"/>
          <CheckBox optional name="commission" checked={commission} labelProp="Allow product commission"/>
          <CheckBox optional name="do_delivery" checked={do_delivery} labelProp="Add delivery to sales"/>
          <CheckBox optional name="auto_payment_confirm" checked={auto_payment_confirm}
                    labelProp="Add automatic confirm transfer method sales"/>
          <CheckBox optional name="subscription_services" checked={subscription_services}
                    labelProp="Customer Subscription Services"/>
          <CheckBox optional afterCheck={handleCheckForeignCurrency} name="exchange_rate" checked={exchange_rate}
                    labelProp="Use foreign currency"/>
          <CheckBox optional name="dashboard_financial_records" checked={dashboard_financial_records}
                    labelProp="Display financial Records on dashboard"/>
    
          {isShowExchangeRate && (
            <FlexStyled>
              <Select
                options={currency_list}
                displayKey="name"
                valueKey="code"
                name="ex_currency"
                displayName="currency_name"
                label="Exchange Currency"
                placeholder="Select exchange currency"
              />
        
              <Input type="number" name="currency_exchange_rate" label="Exchange Rate"
                     placeholder="Enter exchange rate/amount in Naira"/>
            </FlexStyled>
          )}
  
          <Select
            options={sellScreenLayouts}
            displayKey="name"
            valueKey="id"
            name="sell_screen"
            displayName="sell_screen_name"
            label="Sell Screen Layout"
            placeholder="Select sell screen layout"
          />
          
          <Input name="notify_expiry" label="Expiry notification interval" placeholder="Enter number of days for notification" />
  
          <Input optional formatNumber type="number" name="sales_margin" label="Sales margin (percentage)" placeholder="Enter sales margin in percentage" />
          <CheckBox optional name="use_sales_margin" checked={use_sales_margin} labelProp="Activate sales margin" />
          
          <Input optional formatNumber type="number" name="debt_ceiling" label="General Customer Debt Limit"
                 placeholder="Enter maximum debt limit"/>
          
          <Button text="Update Settings" type="submit" isLoading={updateBusinessSettingsMutation.isLoading} />
        </Form>
      )}
    </BusinessSettingsStyled>
  )
}

export default BusinessSettings