import {
  Chips,
  CreateStaffModal,
  NoDataLayout,
  PageHeader,
  PageHeaderWithButton,
  PageSummaryCard,
  ScreenLoading,
  SearchAndFilter,
  Table,
  TableItemWithDeleteAndEditAction,
  TablePagination
} from "../../../components";
import {
  useCreateStaffMutation,
  useDeleteStaffMutation,
  useEditStaffMutation,
  useStaffByBusinessIdQuery,
} from "../../../hooks/useServices/useStaffServices";

import {Fragment, useEffect, useState} from "react";
import {AddCircle} from "iconsax-react";
import {useModal} from "../../../hooks";
import {NoStaffImage} from "../../../assets/images";
import {FlexStyled, PageSummaryStyled} from "../../../styles/utilStyles";
import {SetUpLayoutStyled, SetUpMainStyled} from "../setUp/SetUpStyles";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import useQueryDebounce from "../../../hooks/useUtils/useQueryDebounce";
import useSearchQuery from "../../../hooks/useUtils/useSearchQuery";
import NoQueryData from "../../../components/Utils/NoQueryData";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {staffFormDefaultValues} from "../../../data/defaultFormValues";
import EditStaffModal from "../../../components/Modal/StaffModals/EditStaffModal";
import {actionsPermissions} from "../../../data/permissions";
import staffQueryKey from "../../../hooks/useServices/useStaffServices/staffQueryKey";
import {getAllStaffRestructure} from "../../../services/staffServices/staffRestructure";
import {useSearchParams} from "react-router-dom";


const Staff = () => {
  const [urlParams, setUrlParams] = useSearchParams()
  const { navigate, toast } = useGlobalContext()
  const [showEditStaffModal, setShowEditStaffModal] = useModal()
  const [showCreateStaffModal, setShowCreateStaffModal] = useModal()
  const [editStaffFormValues, setEditStaffFormValues] = useState(staffFormDefaultValues)
  
  const [tabState, setTabState] = useState(urlParams.get('status') || "active")
  
  useEffect(() => {
    if (!urlParams.get('status')) {
      setUrlParams(prev => ({...prev, status: "active"}))
    }
  }, [urlParams.get('status')]);
  
  
  const { staffTable } = tableHeadersAndValueKeys
  const { debounceState, debounceReducer } = useQueryDebounce()
  const deleteStaffMutation = useDeleteStaffMutation({ successFn: () => {}  })
  const editStaffMutation = useEditStaffMutation({
    successFn: ({isUndelete}) => {
      if (!isUndelete) setShowEditStaffModal()
    }
  })
  const {mainQuery, ...allStaffs} = useSearchQuery({
    ...debounceState,
    filterValue: urlParams.get('status') || ""
  }, useStaffByBusinessIdQuery)
  const createStaffMutation = useCreateStaffMutation({ successFn: setShowCreateStaffModal })

  const handleCreateStaff = (values) => {
    createStaffMutation.mutate(values)
  }
  
  const handleEditStaff = (values) => {
    editStaffMutation.mutate(values)
  }
  
  const handleDeleteAction = (staff) => {
    toast.confirm(
      "Are you sure?",
      () => deleteStaffMutation.mutate(staff.id),
    )
  }
  
  const handleUnDeleteAction = (staff) => {
    toast.confirm(
      "Are you sure you want to activate this staff?",
      () => editStaffMutation.mutate({...staff, is_blocked: "False"}),
    )
  }
  
  const handleEditAction = (staff) => {
    setEditStaffFormValues(staff)
    setShowEditStaffModal()
  }

  const handleViewStaff = (staff) => {
    navigate(`/dashboard/staff/${staff.id}`)
  }
  
  const handleSwitchStaffList = (type) => {
    setTabState(type)
    setUrlParams(prev => ({...prev, status: type}))
  }

  return (
    <SetUpMainStyled>
      {showCreateStaffModal && <CreateStaffModal onClose={setShowCreateStaffModal} handleSubmit={handleCreateStaff} mutation={createStaffMutation} />}
      {showEditStaffModal && <EditStaffModal onClose={setShowEditStaffModal} handleSubmit={handleEditStaff} mutation={editStaffMutation} formValues={editStaffFormValues} /> }
  
      {!!mainQuery.isError && (
        <ScreenLoading refetchFn={allStaffs.refetch} />
      )}
      
      <Fragment>
        {allStaffs.isEmpty && (
          <Fragment>
            <PageHeader title="Employees" />
            <NoDataLayout
              imgWidth={45}
              imgAlt="No expense"
              imgSrc={NoStaffImage}
              heading="No staff yet"
              subHeading="Add staff to monitor and view their activities anytime"
              buttonText="Add new staff"
              buttonClick={setShowCreateStaffModal}
              permissionCode={actionsPermissions.createStaff}
            />
          </Fragment>
        )}

        {allStaffs.showTable && (
          <Fragment>
            <PageHeaderWithButton buttonClick={setShowCreateStaffModal} title="Employees" buttonText="Create" Icon={AddCircle} permissionCode={actionsPermissions.createStaff} />

            <PageSummaryStyled>
              <PageSummaryCard
                isLoading={allStaffs.isQueryLoading}
                only
                title="Total Number of Employees"
                value={allStaffs?.data?.count}
              />
            </PageSummaryStyled>
            
            <FlexStyled>
              <Chips title="Active Staffs" active={tabState === "active"}
                     onClick={() => handleSwitchStaffList('active')}/>
              <Chips title="Blocked Staffs" active={tabState === "blocked"}
                     onClick={() => handleSwitchStaffList('blocked')}/>
            </FlexStyled>
            
            <SetUpLayoutStyled>
              <SearchAndFilter
                noFilter
                dispatch={debounceReducer}
                searchPlaceholder="Search staff by name, phone, email"
              />

              <Table headers={staffTable.headers} noCols={staffTable.headers.length}>
                {allStaffs.isQueryWithData && (
                  allStaffs?.data?.results.map((items, k) =>
                    <TableItemWithDeleteAndEditAction
                      key={k}
                      data={items}
                      count={k}
                      pageCount={allStaffs.currentCount}
                      keys={staffTable.values}
                      onClick={handleViewStaff}
                      deleteAction={handleDeleteAction}
                      editAction={handleEditAction}
                      unDeleteAction={handleUnDeleteAction}
                      deletePermission={urlParams.get('status') === "blocked" ? actionsPermissions.NO_DISPLAY : actionsPermissions.deleteStaff}
                      editPermission={urlParams.get('status') === "blocked" ? actionsPermissions.NO_DISPLAY : actionsPermissions.updateStaff}
                      unDeletePermission={urlParams.get('status') !== "blocked" ? actionsPermissions.NO_DISPLAY : actionsPermissions.updateStaff}
                    />
                  )
                )}

                {allStaffs.isQueryWithNoData && (
                  <NoQueryData text={allStaffs.isSearchEmptyMessage("Staff", "name, phone or email")} />
                )}

                {allStaffs.isQueryLoading && (
                  <TableSkeleton />
                )}
              </Table>

              {!!allStaffs?.showPagination &&
                <TablePagination
                  query={allStaffs}
                  restructureFn={getAllStaffRestructure}
                  queryKey={staffQueryKey.mainList(allStaffs.queryValues)}
                />
              }
            </SetUpLayoutStyled>
  
            {!!allStaffs?.showPagination &&
              <TablePagination
                query={allStaffs}
                restructureFn={getAllStaffRestructure}
                queryKey={staffQueryKey.mainList(allStaffs.queryValues)}
              />
            }
            
          </Fragment>
        )}
      </Fragment>
    </SetUpMainStyled>
  )
}

export default Staff;