import {SearchNormal1} from "iconsax-react";
import {DatePicker, Form, SearchField, Select} from "../index";
import {SearchAndFilterStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import {debounceActions} from "../../hooks/useUtils/useQueryDebounce";
import {Fragment, useEffect, useState} from "react";


const SearchAndFilter = ({
                           searchPlaceholder,
                           dispatch,
                           noFilter,
                           filterPlaceholder,
                           filterOptions,
                           valueKey,
                           displayKey,
                           dateFilter,
                           fromDateValue = "",
                           toDateValue = "",
                           sortFilterOptions,
                           handleSortFilter,
                           showSortFilter,
                           children
                         }) => {
  const [{from_date, to_date}, setDates] = useState({from_date: fromDateValue, to_date: toDateValue})
  
  useEffect(() => {
    if (from_date && to_date) {
      dispatch({
        toDateValue: to_date,
        type: debounceActions.SET_FILTER_BY_TO_DATE
      })
      
      dispatch({
        fromDateValue: from_date,
        type: debounceActions.SET_FILTER_BY_FROM_DATE
      })
    }
  }, [from_date, to_date])
  
  const handleSearch = (value) => {
    dispatch({
      searchValue: value,
      type: debounceActions.SET_SEARCH_FIELD
    })
  }

  const handleFilter = (data) => {
    dispatch({
      filterValue: data?.[valueKey] || "",
      type: debounceActions.SET_FILTER_FIELD
    })
  }
  
  const handleFilterByToDate = (date) => {
    setDates(prev => ({ ...prev, to_date: date }))
  }
  
  const handleFilterByFromDate = (date) => {
    setDates(prev => ({ ...prev, from_date: date }))
  }

  return (
    <SearchAndFilterStyled>
      <SpaceBetweenStyled className="form_space">
       <Form values={{ search: "", filter: "", to_date: toDateValue, from_date: fromDateValue }} >
            <SearchField
              allowScannerInput
              label={dateFilter && "Search"}
              bgDark
              name="search"
              type="search"
              className="search_field"
              placeholder={searchPlaceholder}
              setSearchValueState={handleSearch}
              rightIcon={<SearchNormal1 size={15} />}
            />
  
            {!noFilter && (
              <Select
                id="filter_field"
                bgDark
                clearFilter
                name="filter"
                className="filter_field"
                options={filterOptions}
                valueKey={valueKey}
                displayKey={displayKey}
                updateFn={handleFilter}
                placeholder={filterPlaceholder}
              />
            )}
  
           {showSortFilter && (
             <Select
               bgDark
               clearFilter
               displayName="sort_display_name"
               name="sort_filter"
               className="filter_field"
               options={sortFilterOptions}
               valueKey={valueKey}
               displayKey={displayKey}
               updateFn={handleSortFilter}
               placeholder="Sort by"
             />
           )}
  
  
            {dateFilter && (
              <Fragment>
                <DatePicker
                  label="From Date"
                  bgDark
                  required
                  labelBg="inputBGDark"
                  name="from_date"
                  className="date_field"
                  placeholder="Filter From"
                  onChange={handleFilterByFromDate}
                />
      
                <DatePicker
                  label="To Date"
                  bgDark
                  required
                  labelBg="inputBGDark"
                  name="to_date"
                  className="date_field"
                  placeholder="Filter To"
                  onChange={handleFilterByToDate}
                />
              </Fragment>
            )}
      </Form>
        {children}
      </SpaceBetweenStyled>
    </SearchAndFilterStyled>
  )
}

export default SearchAndFilter