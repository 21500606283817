import {
  ChartAndRecentSalesStyled,
  ProductInfoStyled,
  SingleProductDetailsStyled,
  SingleProductStyled
} from "../../../../styles/singleProductStyles";

import {Fragment, useState} from "react";
import {useTheme} from "styled-components";
import {useParams} from "react-router-dom"
import {AddCircle, ArrowLeft, Barcode, Check, Edit, MinusCirlce, Money, Send2} from "iconsax-react";
import {NoProductImage} from "../../../../assets/images";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {OrdersTableStyled, SingleOrderHeaderStyled} from "../../../../styles/singleOrderStyles";
import {FlexStyled, SectionStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {
  useAddFaultyProductService,
  useAdjustProductCostPriceService,
  useCreateProductMutation,
  useEditProductMutation,
  useGetProductsWithSameSKUService,
  useManageProductStockService,
  useProductByIdQuery,
  useProductDistributionMutation
} from "../../../../hooks/useServices/useProductServices";
import {
  AdjustProductCostPriceModal,
  AreaChart,
  Button,
  Chips,
  CreateProductExpiryModal,
  DistributeProducibleProductModal,
  EditProducibleProductModal,
  EditProductExpiryModal,
  EditProductionRawMaterialModal,
  Image,
  Line,
  ManageStock,
  PageSummaryCard,
  ProductBarcodeModal,
  ProductPriceTag,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
  TableItemWithStatus
} from "../../../../components";
import useModal from "../../../../hooks/useModal";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {useProductAnalyticsByIdService} from "../../../../hooks/useServices/useAnalyticsServices";
import DistributeProductModal from "../../../../components/Modal/InventoryModal/ProductModals/DistributeProductModal";
import {dashboardSalesChartFormatter} from "../../../../utils/chartDataFormatter";
import {actionsPermissions} from "../../../../data/permissions";
import {useActionPermissionFn} from "../../../../hooks/usePermissions/useActionPermission";
import {productFormDefaultValues} from "../../../../data/defaultFormValues";
import EditProductModal from "../../../../components/Modal/InventoryModal/ProductModals/EditProductModal";
import {
  formatProductFormForEditing,
  formatProductFormForSubmission,
  handleEditProductAttachments
} from "../../../../utils/formatForms/product";
import {useBusinessSettingQuery} from "../../../../hooks/useServices/useBusinessSettingServices";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import {ContainerStyled} from "../../../../styles/DashboardStyles";
import tableHeadersAndValueKeys from "../../../../data/tableHeadersAndValueKeys";
import {getRecentSingleOrderStructure} from "../../../../services/orderServices/orderServiceStructure";
import {topNData} from "../../../../utils";
import {
  handleFormatCustomUnitMeasurementForFormEditing,
  handleFormatProductionAttachmentForFormEditing
} from "../../../../utils/products";
import {
  useCreateProductExpiryMutation,
  useEditProductExpiryMutation,
  useProductExpiryQuery
} from "../../../../hooks/useServices/useProductExpiryServices";
import useOrderQueryWithSearchAndFilter from "../../../../hooks/useUtils/useOrderQueryWithSearchAndFilter";
import NoQueryData from "../../../../components/Utils/NoQueryData";
import {ListDropDownStyled, MenuDropDownStyled, MenuListStyled} from "../../../../styles/listStyles";
import {SlOptionsVertical} from "react-icons/sl";
import {
  useCreateProductionDistributionMutation
} from "../../../../hooks/useServices/useProductionServices/useProductionService";
import queryParamKeys from "../../../../utils/queryParamKeys";
import ManageFaultyProductModal
  from "../../../../components/Modal/InventoryModal/ProductModals/ManageFaultyProductModal";
import AdjustProductSellingPriceModal
  from "../../../../components/Modal/InventoryModal/ProductModals/AdjustProductSellingPriceModal";


const ProductInfo = ({title, value}) => (
  <ProductInfoStyled>
    <Heading color="black" size={1.1}>{title}:</Heading>
    <Paragraph color="secondary" size={1.1}>{value}</Paragraph>
  </ProductInfoStyled>
)

const SingleProduct = () => {
  const theme = useTheme()
  const {productId} = useParams()
  const actionPermission = useActionPermissionFn()
  const businessSettings = useBusinessSettingQuery()
  const {navigate, isMobile, toast, isOffline} = useGlobalContext()
  
  const {
    productCostPricesTable,
    orderTable,
    productUnitMeasurementsTable,
    productExpiryTable,
    salesAttachmentTable,
    productionAttachmentTable,
    productionStagesTable,
    inventoryTable
  } = tableHeadersAndValueKeys
  
  const {
    numberFormatter,
    currencyFormatter,
    currencyFormatterWithNoFraction,
    shortDateFormatter,
    dateOnlyFormatter, dateFormatter
  } = useDataTypeFormatter()
  const {
    isSuccess: isProductSuccess,
    data: product,
    status,
    refetch: refetchProductQuery,
    isLoading: isProductLoading
  } = useProductByIdQuery(productId)
  const {
    data: productAnalytics,
    isSuccess: isProductAnalyticsSuccess,
    isLoading: isProductAnalyticsLoading,
    refetch: refetchProductAnalytics
  } = useProductAnalyticsByIdService()
  useGetProductsWithSameSKUService(productId)
  const [manageType, setMangeType] = useState("")
  
  
  const [productForEdit, setProductForEdit] = useState(product)
  const [showManageStock, setShowManageStock] = useModal()
  const [showProductBarcode, setShowProductBarcode] = useModal()
  const [showProductPriceTag, setShowProductPriceTag] = useModal()
  const [showDistributeProduct, setShowDistributeProduct] = useModal()
  const [showAdjustCostProduct, setShowAdjustCostProduct] = useModal()
  const [showDuplicateProductModal, setShowDuplicateProductModal] = useModal()
  const [showManageFaultyProductModal, setShowManageFaultyProductModal] = useModal()
  const [showAdjustProductSellingPriceModal, setShowAdjustProductSellingPriceModal] = useModal()
  
  
  const [showCreateProductExpiryModal, setShowCreateProductExpiryModal] = useModal()
  const [showEditProductExpiryModal, setShowEditProductExpiryModal] = useModal()
  const [editExpiryProductValues, setEditExpiryProductValues] = useState({})
  
  const {
    mainQuery: m,
    ...allProductExpiry
  } = useOrderQueryWithSearchAndFilter({product: product?.id}, useProductExpiryQuery)
  const createProductExpiryMutation = useCreateProductExpiryMutation({successFn: setShowCreateProductExpiryModal})
  const editProductExpiryMutation = useEditProductExpiryMutation({successFn: setShowEditProductExpiryModal})
  
  const duplicateProductMutation = useCreateProductMutation({successFn: setShowDuplicateProductModal})
  const [duplicateProductFormValues, setDuplicateProductFormValues] = useState(productFormDefaultValues)
  const manageStockMutation = useManageProductStockService({successFn: setShowManageStock})
  const adjustProductCostPriceMutation = useAdjustProductCostPriceService({successFn: setShowAdjustCostProduct})
  
  const addFacultyProductMutation = useAddFaultyProductService({successFn: setShowManageStock})
  
  const distributeProductMutation = useProductDistributionMutation({successFn: setShowDistributeProduct})
  const productionDistributionMutation = useCreateProductionDistributionMutation({successFn: setShowDistributeProduct})
  
  const [showEditProductModal, setShowEditProductModal] = useModal()
  const editProductMutation = useEditProductMutation({
    successFn: () => {
      if (showEditProductModal) setShowEditProductModal()
      if (showManageFaultyProductModal) setShowManageFaultyProductModal()
    }
  })
  
  
  const chartConfig = {
    // yAxisWidth: 0,
    // width: isMobile ? 350 : 660,
    // height: isMobile ? 180 : 246,
    
    width: isMobile ? 550 : 1200,
    height: isMobile ? 270 : 320,
    
    dateFormatter: isMobile ? shortDateFormatter : dateOnlyFormatter
  }
  
  const handleOpenManageStockModal = (type) => {
    if(type === "Add Stock") {
      navigate("/dashboard/inventory/purchase_order/")
      return
    }
    
    setMangeType(type)
    setShowManageStock()
  }
  
  const handleManageStock = (values) => {
    const data = {...values, product: productId}
    data.is_in = values.adjust_type === "increase"
  
    if (values.adjust_type === "decrease" && (Number(values.quantity) > Number(product.stock_unit)) && (!businessSettings.sell_below_stock && product.use_stock)) {
      toast.error("You cannot reduce below stock unit", "error")
      return
    }
  
    manageStockMutation.mutate(data)
  }

  const handleProductDistribution = (values) => {
    if(product?.type === "producible") {
      if((Number(values.products[0].quantity) > Number(product.stock_unit)) && (!businessSettings.sell_below_stock && product.use_stock)) {
        toast.error("You cannot distribute above available stock unit")
        return
      }
      
      productionDistributionMutation.mutate(values)
      return
    }
  
    if((Number(values.qty) > Number(product.stock_unit)) && (!businessSettings.sell_below_stock && product.use_stock)) {
      toast.error("You cannot distribute above available stock unit")
      return
    }
    
    distributeProductMutation.mutate(values)
  }

  const handleShowDuplicateProduct = () => {
    const productToDuplicate = {...product}
    delete productToDuplicate.sku

    setDuplicateProductFormValues(productToDuplicate)
    setShowDuplicateProductModal()
  }

  const handleDuplicateProductSubmission = (newProduct) => {
    const newVales = { ...newProduct, duplicate: true }

    if(product.image_path === newProduct.image_path) {
      newVales.init_product_id = product.id
      delete newVales.id
      delete newVales.image_path
    }

    duplicateProductMutation.mutate(newVales)
  }
  
  const handleEditProduct = (values) => {
    const newValues = {...values}
    const formattedValues = formatProductFormForSubmission(values)
  
    if (product.image_path === newValues.image_path) {
      delete formattedValues.image_path
    }
  
    editProductMutation.mutate(formattedValues)
  }
  
  const refetchQueries = async () => {
    await refetchProductQuery()
    await refetchProductAnalytics()
  }
  
  const handleProductDataBeforeEdit = (ignore = false) => {
    const values = {...product, prepare_time_format: product.prepare_time}
    
    const formattedValues = formatProductFormForEditing(values)
    const formattedValues2 = handleEditProductAttachments(formattedValues)
    const formattedValues3 = handleFormatCustomUnitMeasurementForFormEditing(formattedValues2, currencyFormatter)
    const formattedValues4 = handleFormatProductionAttachmentForFormEditing(formattedValues3)
    
    setProductForEdit(formattedValues4)
    if (!ignore) {
      setShowEditProductModal()
    }
  }
  
  const handleSetProductExpiryForExpiry = (values) => {
    setEditExpiryProductValues(values)
    setShowEditProductExpiryModal()
  }
  
  const handleCreateProductExpiry = (formValues) => {
    createProductExpiryMutation.mutate({ ...formValues, product: product?.id })
  }
  
  const handleEditProductExpiry = (formValues) => {
    editProductExpiryMutation.mutate(formValues)
  }
  
  const getAllSalesAttachment = () => {
    if(!product?.product_attachment) return []
    return product?.product_attachment?.filter(attachment => attachment.type === "sales_attachment")
  }
  
  const getAllProductionAttachment = () => {
    if(!product?.product_attachment) return []
    return product?.product_attachment?.filter(attachment => attachment.type === "production_attachment")
  }
  
  const handlePublishProduct = () => {
    if (editProductMutation.isLoading) return
    
    const productValue = {...product}
    delete productValue.image_path
    editProductMutation.mutate({...productValue, is_published: !product.is_published})
  }
  
  return (
    <SingleProductStyled>
      {(showEditProductModal && product?.type === "producible") && (
        <EditProducibleProductModal onClose={setShowEditProductModal} handleSubmit={handleEditProduct}
                                    mutation={editProductMutation} formValues={productForEdit}/>
      )}
      
      {(showEditProductModal && product?.type === "raw_material") && (
        <EditProductionRawMaterialModal onClose={setShowEditProductModal} handleSubmit={handleEditProduct}
                                        mutation={editProductMutation} formValues={productForEdit}/>
      )}
  
      {(showEditProductModal && product?.type === "general_product") && (
        <EditProductModal onClose={setShowEditProductModal} handleSubmit={handleEditProduct}
                          mutation={editProductMutation} formValues={productForEdit}/>
      )}
  
      {(showDistributeProduct && product?.type === "general_product") && (
        <DistributeProductModal onClose={setShowDistributeProduct} handleSubmit={handleProductDistribution}
                                mutation={distributeProductMutation}/>
      )}
  
      {(showAdjustCostProduct && product?.type === "general_product") && (
        <DistributeProductModal onClose={setShowAdjustCostProduct} handleSubmit={handleProductDistribution}
                                mutation={distributeProductMutation}/>
      )}
  
      {(showDistributeProduct && product?.type === "producible") && (
        <DistributeProducibleProductModal onClose={setShowDistributeProduct} handleSubmit={handleProductDistribution}
                                          mutation={productionDistributionMutation}/>
      )}
  
      {showDuplicateProductModal &&
        <EditProductModal formValues={duplicateProductFormValues} onClose={setShowDuplicateProductModal}
                          title="Duplicate Product" mutation={duplicateProductMutation}
                          handleSubmit={handleDuplicateProductSubmission}/>}
      {showManageStock && <ManageStock title={manageType} onClose={setShowManageStock} handleSubmit={handleManageStock}
                                       mutation={manageStockMutation} mutation2={addFacultyProductMutation}/>}
  
  
      {showAdjustCostProduct &&
        <AdjustProductCostPriceModal onClose={setShowAdjustCostProduct}
                                     handleSubmit={adjustProductCostPriceMutation.mutate}
                                     mutation={adjustProductCostPriceMutation}/>}
  
      {showAdjustProductSellingPriceModal && (
        <AdjustProductSellingPriceModal
          productId={productId}
          onClose={setShowAdjustProductSellingPriceModal}
        />
      )}
  
      {showProductBarcode && <ProductBarcodeModal product={product} onClose={setShowProductBarcode}/>}
      {showCreateProductExpiryModal &&
        <CreateProductExpiryModal handleSubmit={handleCreateProductExpiry} onClose={setShowCreateProductExpiryModal}
                                  mutation={createProductExpiryMutation}/>}
      {showEditProductExpiryModal &&
        <EditProductExpiryModal handleSubmit={handleEditProductExpiry} onClose={setShowEditProductExpiryModal}
                                mutation={editProductExpiryMutation} formValues={editExpiryProductValues}/>}
      {showProductPriceTag && <ProductPriceTag product={product} onClose={setShowProductPriceTag}/>}
      {showManageFaultyProductModal &&
        <ManageFaultyProductModal
          mutation={editProductMutation}
          formValues={productForEdit}
          handleSubmit={handleEditProduct}
          onClose={setShowManageFaultyProductModal}/>}
      
      
      {status === "error" && (
        <ScreenLoading refetchFn={refetchQueries}/>
      )}
      
      <Fragment>
        <SingleOrderHeaderStyled>
          <FlexStyled className="navigation_section">
            <ArrowLeft color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)}/>
            <FlexStyled>
              <Paragraph noTop color="menuGray">Back to product list</Paragraph>
              <Heading color="black">{product?.name}</Heading>
            </FlexStyled>
          </FlexStyled>
          
          {!isOffline && (
            <FlexStyled className="action_buttons">
              <Button text="Edit" onClick={handleProductDataBeforeEdit} Icon={Edit}
                      permissionCode={actionsPermissions.updateProduct}/>
              {(product?.type === "general_product") && (
                <Button text="Purchase" onClick={() => handleOpenManageStockModal("Add Stock")} Icon={AddCircle}
                        permissionCode={actionsPermissions.createPurchaseOrder}/>
              )}
  
              {(product?.type === "general_product" || product?.type === "raw_material") && (
                <Button text="Adjust Stock" onClick={() => handleOpenManageStockModal("Reduce Stock")}
                        Icon={MinusCirlce} permissionCode={actionsPermissions.addNewStock}/>
              )}
  
              {(product?.type === "general_product") && (
                <Button text="Adjust Cost" onClick={setShowAdjustCostProduct} Icon={AddCircle}
                        permissionCode={actionsPermissions.createProduct}/>
              )}
  
              {/*{(product?.type === "general_product") && (*/}
              {/*  <Button text="Adjust Selling" onClick={setShowAdjustCostProduct} Icon={AddCircle}*/}
              {/*          permissionCode={actionsPermissions.createProduct}/>*/}
              {/*)}*/}
  
              {/*<Button text="Transfer" Icon={Send2} onClick={setShowDistributeProduct}*/}
              {/*        permissionCode={actionsPermissions.distributeProduct}/>*/}
              {/*<Button text="Duplicate" onClick={handleShowDuplicateProduct} Icon={AddCircle} permissionCode={actionsPermissions.distributeProduct} />*/}
              {/*<Button text="Barcode" onClick={setShowProductBarcode} Icon={Barcode} />*/}
  
  
              <MenuDropDownStyled>
                <SlOptionsVertical size={25} color="black"/>
    
                <div className="container">
                  <ListDropDownStyled>
                    {actionPermission.check(actionsPermissions.distributeProduct) && (
                      <MenuListStyled onClick={setShowDistributeProduct}>
                        <Send2 size={20} color="black"/>
                        <Heading size={1.1} color="black">Transfer</Heading>
                      </MenuListStyled>
                    )}
        
                    {actionPermission.check(actionsPermissions.createProduct) && (
                      <MenuListStyled onClick={setShowAdjustProductSellingPriceModal}>
                        <Money size={20} color="black"/>
                        <Heading size={1.1} color="black">Adjust Selling Price</Heading>
                      </MenuListStyled>
                    )}
        
                    <MenuListStyled onClick={setShowProductBarcode}>
                      <Barcode size={20} color="black"/>
                      <Heading size={1.1} color="black">View Barcode</Heading>
                    </MenuListStyled>
        
                    <MenuListStyled onClick={setShowProductPriceTag}>
                      <Barcode size={20} color="black"/>
                      <Heading size={1.1} color="black">View Price Tag</Heading>
                    </MenuListStyled>
        
                    {(product?.type === "general_product") && (
                      <MenuListStyled onClick={handlePublishProduct}>
                        <Check size={20} color="black"/>
                        <Heading size={1.1}
                                 color="black">{product?.is_published ? "Un-publish" : "Publish"} Product</Heading>
                      </MenuListStyled>
                    )}
                  </ListDropDownStyled>
                </div>
              </MenuDropDownStyled>
            </FlexStyled>
          )}
        </SingleOrderHeaderStyled>
        
        <Line/>
        
        {isProductLoading && (
          <TableSkeleton count={1}/>
        )}
        
        {isProductSuccess && (
          <FlexStyled $gap={3} className="product_info">
            <ProductInfo title="SKU/Barcode" value={product?.sku}/>
  
            {(product.extra_sku?.split(", ") || []).map((key, index) => (
              <ProductInfo key={key} title={`SKU/Barcode ${index + 2}`} value={key}/>
            ))}
  
            <ProductInfo title="Category" value={product?.category_name}/>
            <ProductInfo title="Sub-category" value={product?.sub_category_name}/>
  
            {!!product?.nested_category_1_name && (
              <ProductInfo title="Sub Category 2" value={product?.nested_category_1_name}/>
            )}
            {!!product?.nested_category_2_name && (
              <ProductInfo title="Sub Category 3" value={product?.nested_category_2_name}/>
            )}
            {!!product?.nested_category_3_name && (
              <ProductInfo title="Sub Category 4" value={product?.nested_category_3_name}/>
            )}
  
            <ProductInfo title="Unit Increment" value={numberFormatter(product?.unit_increment)}/>
            <ProductInfo title="Date Created" value={dateFormatter(product?.created_at)}/>
            <ProductInfo title="Date Updated" value={dateFormatter(product?.updated_at)}/>
          </FlexStyled>
        )}
        
        <FlexStyled className="product_info">
        </FlexStyled>
        
        
        <SingleProductDetailsStyled>
          <Image src={product?.image_path || NoProductImage} alt={product?.name}/>
          
          <FlexStyled className="details">
            {actionPermission?.check(actionsPermissions.createProduct) && (
              <PageSummaryCard
                isLoading={isProductLoading}
                title="Cost Price"
                value={currencyFormatter(product?.cost_price || 0)}
              />
            )}
            
            <PageSummaryCard
              isLoading={isProductLoading}
              title="Selling Price"
              value={currencyFormatter(product?.selling_price || 0)}
            />
            
            <PageSummaryCard
              isLoading={isProductLoading}
              title="Available Stock"
              value={(product?.use_stock && !isOffline) ? numberFormatter(product?.stock_unit || 0) : "N/A"}
              subText={`Faulty Stock: ${numberFormatter(product?.faulty_stock_unit)}`}
            />
            
            {actionPermission?.check(actionsPermissions.createProduct) && (
              <Fragment>
                <PageSummaryCard
                  isLoading={isProductLoading}
                  title="Total Cost In Stock"
                  value={(product?.use_stock && !isOffline) ? currencyFormatter(productAnalytics?.value_of_cost || 0) : "N/A"}
                />
                <PageSummaryCard
                  isLoading={isProductLoading}
                  title="Total Stock Value"
                  value={(product?.use_stock && !isOffline) ? currencyFormatter(productAnalytics?.value_of_selling || 0) : "N/A"}
                />
                
                <PageSummaryCard
                  isLoading={isProductLoading}
                  title="Total Profit Value"
                  value={(product?.use_stock && !isOffline) ? currencyFormatter(productAnalytics?.value_of_profit || 0) : "N/A"}
                />
              </Fragment>
            )}
          </FlexStyled>
        </SingleProductDetailsStyled>
        
        <ChartAndRecentSalesStyled>
          <div className="charts">
            <Heading color="black" size={1.3}>Sales Analytics</Heading>
            {isProductAnalyticsSuccess && (
              <AreaChart
                config={chartConfig}
                valueFormatter={currencyFormatterWithNoFraction}
                data={dashboardSalesChartFormatter(productAnalytics?.graph_data.sales, chartConfig.dateFormatter)}
              />
            )}
            
            {isProductAnalyticsLoading && (
              <ContainerStyled padding>
                <TableSkeleton count={3} height={60}/>
              </ContainerStyled>
            )}
          </div>
          
          {/*<div className="recent_sales">*/}
          {/*  <SpaceBetweenStyled>*/}
          {/*    <Heading color="black" size={1.3}>Recent Sales</Heading>*/}
          {/*    <Paragraph color="secondary" bold size={.9}>Sell All</Paragraph>*/}
          {/*  </SpaceBetweenStyled>*/}
          {/*  <Line />*/}
          
          {/*  {isProductAnalyticsLoading && (*/}
        {/*    <TableSkeleton count={4} height={45} />*/}
        {/*  )}*/}
        
        {/*  {isProductAnalyticsSuccess && (*/}
        {/*    <Fragment>*/}
        {/*      {productAnalytics?.recent_order?.map(order => (*/}
        {/*        <SalesCard*/}
        {/*          title={order?.group_id}*/}
        {/*          date={shortDateFormatter(order?.date_created)}*/}
        {/*          price={currencyFormatter(order?.total_cost)}*/}
        {/*          status="Completed"*/}
        {/*        />*/}
        {/*      ))}*/}
        {/*  */}
        {/*      {productAnalytics?.recent_order?.length < 1 && (*/}
        {/*        <Heading color="black">No Sales Available</Heading>*/}
        {/*      )}*/}
        {/*    </Fragment>*/}
        {/*  )}*/}
        {/*</div>*/}
      </ChartAndRecentSalesStyled>
      
      
      {(productAnalytics?.recent_order?.length > 0 && !isOffline) && (
        <SectionStyled>
          <SpaceBetweenStyled>
            <Heading size={1.25} bold color="black">Recent Orders</Heading>
          </SpaceBetweenStyled>
          
          <Table bg headers={orderTable.headers} noCols={orderTable.headers.length}>
            {topNData(getRecentSingleOrderStructure(productAnalytics?.recent_order), 5)?.map((items, k) =>
              <TableItemForGroupOrders
                statusPosition={5}
                key={k}
                data={items}
                count={k}
                pageCount={1}
                keys={orderTable.values}
              />
            )}
          </Table>
        </SectionStyled>
      )}
        
        {(product?.faulty_products?.length > 0 && !isOffline) && (
          <SectionStyled>
            <SpaceBetweenStyled>
              <Heading bold size={1.25} color="black">Faulty Product Log</Heading>
              <FlexStyled>
                <Chips title="View all"
                       onClick={() => navigate(`/dashboard/inventory/faulty-products-logs/?${queryParamKeys.productID}=${product?.id}&${queryParamKeys.productName}=${product?.name}`)}/>
                <Chips title="Manage Faulty Product" onClick={() => {
                  handleProductDataBeforeEdit(true)
                  setShowManageFaultyProductModal()
                }
                }/>
              </FlexStyled>
            </SpaceBetweenStyled>
            
            <Table bg headers={inventoryTable.headers} noCols={inventoryTable.headers.length}>
              {product?.faulty_products?.map((items, k) =>
                <TableItemForGroupOrders
                  statusPosition={3}
                  key={k}
                  data={items}
                  count={k}
                  pageCount={1}
                  keys={inventoryTable.values}
                />
              )}
            </Table>
          </SectionStyled>
        )}
        
        {!!product?.cost_prices && product?.cost_prices?.length > 0 && (
          <OrdersTableStyled>
            <Heading size={1.4} color="black">Cost Prices</Heading>
            <Table headers={productCostPricesTable.headers} equalWidth noCols={productCostPricesTable.headers.length}>
              {product?.cost_prices?.map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={productCostPricesTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {!!product?.meta_measurement && product?.meta_measurement?.length > 0 && (
          <OrdersTableStyled>
            <Heading size={1.4} color="black">Unit Measurements</Heading>
            <Table headers={productUnitMeasurementsTable.headers} equalWidth
                   noCols={productUnitMeasurementsTable.headers.length}>
              {product?.meta_measurement?.map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={productUnitMeasurementsTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {!!product?.faulty_meta_measurement && product?.faulty_meta_measurement?.length > 0 && (
          <OrdersTableStyled>
            <Heading size={1.4} color="black">Faulty Unit Measurements</Heading>
            <Table headers={productUnitMeasurementsTable.headers} equalWidth
                   noCols={productUnitMeasurementsTable.headers.length}>
              {product?.faulty_meta_measurement?.map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={productUnitMeasurementsTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {!!product?.product_attachment && getAllSalesAttachment().length > 0 && (
          <OrdersTableStyled>
            <Heading size={1.4} color="black">Sales Attachment</Heading>
            <Table headers={salesAttachmentTable.headers} equalWidth noCols={salesAttachmentTable.headers.length}>
              {getAllSalesAttachment().map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={salesAttachmentTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {!!product?.product_attachment && getAllProductionAttachment().length > 0 && (
          <OrdersTableStyled>
            <Heading size={1.4} color="black">Production Attachment</Heading>
            <Table headers={productionAttachmentTable.headers} equalWidth
                   noCols={productionAttachmentTable.headers.length}>
              {getAllProductionAttachment().map((items, k) =>
                <TableItemWithStatus
                  count={k}
                  pageCount={1}
                  keys={productionAttachmentTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {(!!product?.stages && product?.stages?.length > 0) && (
          <OrdersTableStyled $noPadding>
            <Heading size={1.4} color="black">Production Stages</Heading>
            <Table headers={productionStagesTable.headers} noCount noHeader noPadding>
              {product?.stages.map((items, k) =>
                <TableItemWithStatus
                  noCount
                  count={k}
                  pageCount={1}
                  keys={productionStagesTable.values}
                  statusPosition={9}
                  key={k}
                  data={items}
                  noAction
                />
              )}
            </Table>
          </OrdersTableStyled>
        )}
        
        {allProductExpiry.showTable && (
          <OrdersTableStyled>
            <SpaceBetweenStyled className="product_expiry">
              <Heading size={1.4} color="black">Product Expiry</Heading>
              {!isOffline && <Button text="Add" small onClick={setShowCreateProductExpiryModal}/>}
            </SpaceBetweenStyled>
            
            <Table headers={productExpiryTable.headers} equalWidth noCols={productExpiryTable.headers.length}>
              {allProductExpiry.isQueryWithData && (
                allProductExpiry.data?.results?.map((items, k) =>
                  <TableItemWithStatus
                    count={k}
                    pageCount={1}
                    keys={productExpiryTable.values}
                    statusPosition={9}
                    key={k}
                    data={items}
                    onView={handleSetProductExpiryForExpiry}
                  />
                )
              )}
              
              {allProductExpiry.isQueryWithNoData && (
                <NoQueryData text={allProductExpiry.emptyMessage("Product Expiry")}/>
              )}
              
              {allProductExpiry.isQueryLoading && (
                <TableSkeleton/>
              )}
            </Table>
          </OrdersTableStyled>
        )}
      </Fragment>

  </SingleProductStyled>
 )
}

export default SingleProduct;