import {CustomerAndStaffReviewCardStyled} from "../../styles/cardStyles";
import {Heading, Heading2, Paragraph} from "../../styles/textStyles";
import {Fragment} from "react";
import {PageSummaryCardSkeleton} from "../index";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";

const CustomerAndStaffReviewCard = ({
                                      title,
                                      value,
                                      subTitle,
                                      statusColor,
                                      noBorder,
                                      className,
                                      isLoading,
                                      onclick,
                                      underlineTitle
                                    }) => {
  const { isOffline } = useGlobalContext()
  
  return (
    <Fragment>
      {isOffline && (
        <CustomerAndStaffReviewCardStyled $statusColor={statusColor} $noBorder={noBorder} className={className}>
          <Heading color="black" size={.9}>No data available</Heading>
          <Heading2 color="black" className="value">Offline</Heading2>
          <Paragraph color="secondary" noTop>{subTitle}</Paragraph>
        </CustomerAndStaffReviewCardStyled>
      )}
      
      {(isLoading && !isOffline) && (
        <PageSummaryCardSkeleton />
      )}
      
      {(!isLoading && !isOffline) && (
        <CustomerAndStaffReviewCardStyled $statusColor={statusColor} $noBorder={noBorder} className={className}>
          <Heading color="black" size={.9} onClick={() => onclick?.()}>
            {underlineTitle && <u>{title}</u>}
            {!underlineTitle && title}
          </Heading>
          <Heading2 color="black" className="value">
            {value}
            {!!statusColor && <span></span>}
          </Heading2>
          <Paragraph color="secondary" noTop>{subTitle}</Paragraph>
        </CustomerAndStaffReviewCardStyled>
      )}
    </Fragment>
  )
}

export default CustomerAndStaffReviewCard