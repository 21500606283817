import {
  AreaChart,
  DashboardPageHeader,
  OverviewCard,
  PieChart,
  ScreenLoading,
  Table,
  TableItemForGroupOrders,
} from "../../components";

import {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {isDisplayDashboardFinancialRecords, topNData} from "../../utils";
import {useTheme} from "styled-components";
import {Heading, Paragraph} from "../../styles/textStyles";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {DashboardStyled} from "../../styles/DashboardStyles";
import chartDataFormatter, {dashboardSalesChartFormatter} from "../../utils/chartDataFormatter";
import useDataTypeFormatter from "../../hooks/useUtils/useDataTypeFormatter";
import {SectionStyled, SpaceBetweenStyled} from "../../styles/utilStyles";
import tableHeadersAndValueKeys from "../../data/tableHeadersAndValueKeys";
import {useGroupOrderByBusinessIdQuery} from "../../hooks/useServices/useOrderServices";
import {BarIcon, BoxSeamIcon, DollarCircleIcon, MoneyOffIcon} from "../../assets/icons";
import {
  useDashboardAnalyticsByBusinessIdService,
  useSubscriptionAnalyticsService
} from "../../hooks/useServices/useAnalyticsServices";
import NoQueryData from "../../components/Utils/NoQueryData";
import handleDateFilterValue from "../../utils/handleDateFilterValue";
import {dateFilterValue} from "../../data/selectField/dateFilter";
import {useBusinessSettingQuery} from "../../hooks/useServices/useBusinessSettingServices";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";


const Dashboard = () => {
  const theme = useTheme()
  const { navigate, isMobile, isOffline } = useGlobalContext()
  const { orderTable } = tableHeadersAndValueKeys
  const { numberFormatter, currencyFormatter, currencyFormatterWithNoFraction, dateOnlyFormatter, shortDateFormatter } = useDataTypeFormatter()
  
  const {
    isSuccess: isOrderSuccess,
    data: allOrders,
    isLoading: isGroupOrdersLoading,
    refetch: refetchDashboardRecentOrders
  } = useGroupOrderByBusinessIdQuery()
  
  
  const {fromDate, toDate} = handleDateFilterValue(dateFilterValue.TODAY)
  const [dateFilterObj, setDateFilterObj] = useState({from_date: fromDate, to_date: toDate})
  
  const { data: subscriptionAnalytics } = useSubscriptionAnalyticsService(dateFilterObj.from_date, dateFilterObj.to_date)
  
  const {
    data: dashboardAnalytics,
    status: dashboardAnalyticsStatus,
    refetch: refetchDashboardAnalytics,
    isLoading: isDashboardAnalyticsLoading,
    isSuccess: isDashboardAnalyticsSuccess
  } = useDashboardAnalyticsByBusinessIdService(dateFilterObj.from_date, dateFilterObj.to_date)
  const {
    count,
    orders,
    debt,
    best_performing_product,
    best_performing_customer,
    graph_data,
    payment_summary
  } = dashboardAnalytics || {}
  
  const businessSettings = useBusinessSettingQuery()
  
  const handleViewSingleOrder = (groupId) => {
    navigate(`/dashboard/orders/${groupId}`)
  }

  const chartConfig = {
    width: isMobile ? 550 : 1200,
    // yAxisWidth: isMobile ? 0 : isLargeDesktop ? 130 : 105,
    yAxisWidth: 0,
    height: isMobile ? 270 : 320,
    dateFormatter: isMobile ? shortDateFormatter : dateOnlyFormatter
  }

  const handleRefetch = () => {
    refetchDashboardAnalytics()
    refetchDashboardRecentOrders()
  }
  
  return (
    <div>
      {(dashboardAnalyticsStatus === "error" && !isOffline) && (
        <ScreenLoading refetchFn={handleRefetch}/>
      )}
      
      <Fragment>
        <DashboardPageHeader setDateFileObj={setDateFilterObj} title="Overview" />
  
        <DashboardStyled>
          {isDisplayDashboardFinancialRecords() && (
            <Fragment>
              <SpaceBetweenStyled>
                <OverviewCard
                  isLoading={isDashboardAnalyticsLoading}
                  onClick={() => navigate("/dashboard/orders")}
                  icon={DollarCircleIcon}
                  title="Total Sales"
                  subText={businessSettings?.subscription_services && `${numberFormatter(count?.orders)} items sold`}
                  bgColor={theme.overViewCard[0]}
                  value={currencyFormatter(count?.sales?.amount)}
                />
                {!businessSettings?.subscription_services && (
                  <OverviewCard
                    isLoading={isDashboardAnalyticsLoading}
                    onClick={() => navigate("/dashboard/orders")}
                    icon={BoxSeamIcon}
                    title="Total Orders"
                    value={numberFormatter(count?.orders)}
                    bgColor={theme.overViewCard[2]}
                  />
                )}
          
                <OverviewCard
                  isLoading={isDashboardAnalyticsLoading}
                  icon={BarIcon}
                  title="Profit"
                  value={currencyFormatter(orders?.current_month_profit?.amount)}
                  bgColor={theme.overViewCard[1]}
                />
          
                {businessSettings?.subscription_services && (
                  <OverviewCard
                    isLoading={isDashboardAnalyticsLoading}
                    onClick={() => navigate("/dashboard/business_purchased_subscriptions/")}
                    icon={BoxSeamIcon}
                    title="Total Subscription Value"
                    subText={`${numberFormatter(subscriptionAnalytics?.total_subscriptions_count)} subscriptions sold`}
                    value={currencyFormatter(subscriptionAnalytics?.total_subscriptions_value)}
                    bgColor={theme.overViewCard[2]}
                  />
                )}
          
                <OverviewCard
                  isLoading={isDashboardAnalyticsLoading}
                  onClick={() => navigate("/dashboard/inventory/all_debts/")}
                  icon={MoneyOffIcon}
                  title="Receivables"
                  value={currencyFormatter(debt?.debt_amount?.amount)}
                  bgColor={theme.overViewCard[3]}
                  subText={`${numberFormatter(debt?.debt_count)} Receivables`}
                />
              </SpaceBetweenStyled>
        
              {!isOffline && (
                <Fragment>
                  {isDashboardAnalyticsLoading && (
                    <TableSkeleton count={2} height={100}/>
                  )}
            
                  {isDashboardAnalyticsSuccess && (
                    <AreaChart
                      config={chartConfig}
                      title="Sales History"
                      valueFormatter={currencyFormatterWithNoFraction}
                      data={dashboardSalesChartFormatter(graph_data?.sales?.slice(7), chartConfig?.dateFormatter)}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}

          <SectionStyled>
            <SpaceBetweenStyled>
              <Heading size={1.25} color="black">Recent Orders</Heading>
              <Link to="/dashboard/orders">
                <Paragraph color="secondary">View all orders</Paragraph>
              </Link>
            </SpaceBetweenStyled>

            <Table bg headers={orderTable.headers} noCols={orderTable.headers.length}>
              {(isOrderSuccess && !isGroupOrdersLoading) && (
                topNData(allOrders?.results, 7).map((items, k) =>
                  <TableItemForGroupOrders
                    count={k}
                    pageCount={1}
                    statusPosition={5}
                    key={k}
                    data={items}
                    keys={orderTable.values}
                    onView={handleViewSingleOrder}
                  />
                )
              )}
  
              {isGroupOrdersLoading && (
                <TableSkeleton />
              )}

              {isOrderSuccess && allOrders.results.length < 1 && (
                <NoQueryData text="No recent orders" />
              )}
            </Table>
          </SectionStyled>
  
          {!isOffline && (
            <Fragment>
              {isDashboardAnalyticsLoading && (
                <TableSkeleton count={2} height={100} />
              )}
    
              {isDashboardAnalyticsSuccess && (
                <Fragment>
                  <SectionStyled twoCol>
                    <PieChart
                      title="Product By Volume"
                      formatter={numberFormatter}
                      data={chartDataFormatter(best_performing_product?.by_volume, "count")}
                    />
                    <PieChart
                      title="Product By Value"
                      formatter={currencyFormatterWithNoFraction}
                      data={chartDataFormatter(best_performing_product?.by_value, "sales")}
                    />
                  </SectionStyled>
        
                  <SectionStyled twoCol>
                    <PieChart
                      formatter={numberFormatter}
                      title="Customer By Volume"
                      data={chartDataFormatter(best_performing_customer?.by_volume, "count")}
                    />
                    <PieChart
                      title="Customer By Value"
                      formatter={currencyFormatterWithNoFraction}
                      data={chartDataFormatter(best_performing_customer?.by_value, "sales")}
                    />
                  </SectionStyled>
                  
                  <SectionStyled twoCol>
                    <PieChart
                      title="Payment Summary"
                      formatter={currencyFormatterWithNoFraction}
                      data={chartDataFormatter(payment_summary, "payment_summary")}
                    />
                  </SectionStyled>
                </Fragment>
              )}
            </Fragment>
          )}
        </DashboardStyled>
      </Fragment>
    </div>
  )
}

export default Dashboard