import {Fragment, memo, useEffect, useRef, useState} from "react";
import {CloseSquare, Trash} from "iconsax-react";
import {Heading, Paragraph} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {Button, CheckBox, Form, Input, Select} from "../../index";
import {useCategoriesQuery} from "../../../hooks/useServices/useCategoryServices";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import {useSubCategoriesByCategoryIdQuery} from "../../../hooks/useServices/useSubCategoryServices";
import {commissionAndDiscountTypeOptions, unitMeasurementOptions} from "../../../data/selectField/product";
import {useModal} from "../../../hooks";
import CropImage from "../CropImage";
import {useFormikContext} from "formik";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useFromModalConnector} from "../../../hooks/useModalConnector";
import modalNames from "../../../hooks/useModalConnector/modalNames";
import {globalReducerActions} from "../../../reducers/globalReducer";
import {useBusinessSettingQuery} from "../../../hooks/useServices/useBusinessSettingServices";
import {useProductQuery} from "../../../hooks/useServices/useProductServices";
import uuid from "react-uuid";
import {useNestedSubCategoriesQuery} from "../../../hooks/useServices/useNestedSubCategoryServices";
import {MAX_NESTED_SUB_CATEGORY_LEVEL} from "../../../utils/constants";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import {
	handleFormatCustomUnitMeasurementForFormSubmission,
	handleFormatProductionAttachmentForFormSubmission,
} from "../../../utils/products";
import {useProductionStagesQuery} from "../../../hooks/useServices/useProductionServices/useProductionStageServices";
import MultiSelectInput from "../../Forms/MultiSelectInput";
import {
	useProductionUnitMeasurementQuery
} from "../../../hooks/useServices/useProductionServices/useProductionUnitMeasurementServices";


const EditProducibleProductModal = ({ onClose, handleSubmit, mutation, formValues }) => {
	const { globalState, globalReducer } = useGlobalContext()
	const businessSettings = useBusinessSettingQuery()
	const { numberFormatter } = useDataTypeFormatter()
	
	const [showExtraMeasurement, setShowExtraMeasurement] = useModal()
	const [categoriesId, setCategoriesId] = useState({
		categoryId: "",
		subCategoryId: "",
		nested_category_1: "",
		nested_category_2: ""
	})
	
	const { data: allProductionStages } = useProductionStagesQuery()
	const [selectedStages, setSelectedStages] = useState([])
	
	const [isShowCommission, setIsShowCommission] = useModal(!!globalState.modalConnector.fromModalFormValues.category)
	const { isSuccess: isSubCategorySuccess, data: allSubCategories } = useSubCategoriesByCategoryIdQuery(globalState.modalConnector.fromModalFormValues.category || categoriesId.categoryId)
	const { isSuccess: isNestedSubCat1Success, data: allNestedSubCat1  } = useNestedSubCategoriesQuery({ sub_category: categoriesId.subCategoryId, level: 1 })
	const { isSuccess: isNestedSubCat2Success, data: allNestedSubCat2  } = useNestedSubCategoriesQuery({ parent: categoriesId.nested_category_1, level: 2 })
	const { isSuccess: isNestedSubCat3Success, data: allNestedSubCat3  } = useNestedSubCategoriesQuery({ parent: categoriesId.nested_category_2, level: 3 })
	const { data: unitMeasurement } = useProductionUnitMeasurementQuery()
	
	const handleClose = () => {
		onClose()
		globalReducer({
			type: globalReducerActions.CLEAR_MODAL_CONNECTOR
		})
	}

	const handleSubmitFn = (data) => {
		const stages = selectedStages.map(stage => ({ name: stage.name, id: stage.id }))
		const formattedValuesForUnitMeasurement = handleFormatCustomUnitMeasurementForFormSubmission(data)
		const formattedValuesForProductsAttachment = handleFormatProductionAttachmentForFormSubmission(formattedValuesForUnitMeasurement)
		
		const formValues = {
			unit_increment: 1,
			type: "producible",
			...formattedValuesForProductsAttachment,
			stages
		}
		
		handleSubmit(formValues)
	}
	
	const AddUnitMeasurement = () => {
		const { values } = useFormikContext()
		const fromModalConnector = useFromModalConnector()
		
		const handleCreateUnitMeasurement = () => {
			fromModalConnector({
				fromModalFormValues: values,
				toModalURL: "/dashboard/set-up/unit-measurement/",
				toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
				fromModalName: modalNames.EDIT_PRODUCIBLE_MODAL,
			})
		}
		
		return (
			<FlexStyled $gap={1}>
				<label className="underline" onClick={handleCreateUnitMeasurement}>Create +</label>
				<label className="underline" onClick={setShowExtraMeasurement}>{ showExtraMeasurement ? "Remove All -" : "Add Extra Measurement +" }</label>
			</FlexStyled>
		)
	}
	
	const getProductFormValues = () => {
		if(!businessSettings.use_sales_margin) return formValues
		
		return {
			...formValues,
			sales_margin: Number(businessSettings?.sales_margin),
			sales_margin_format: Number(businessSettings?.sales_margin)
		}
	}
	
	const handleCostPriceChange = (value, fieldName, formikValues, setFormikField) => {
		if(businessSettings.use_sales_margin) {
			const salesDifferenceBasedOnSalesMargin = (Number(value) * Number(formikValues.sales_margin)) / 100
			const newSellingPrice = Number(value) + salesDifferenceBasedOnSalesMargin
			setFormikField("selling_price", newSellingPrice)
			setFormikField("selling_price_format", numberFormatter(newSellingPrice))
		}
	}
	
	const handleSellingPriceChange = (value, fieldName, formikValues, setFormikField) => {
		if(businessSettings.use_sales_margin) {
			const salesMarginPercentage = ((Number(value) - Number(formikValues.cost_price)) / Number(formikValues.cost_price)) * 100
			setFormikField("sales_margin", salesMarginPercentage)
			setFormikField("sales_margin_format", numberFormatter(salesMarginPercentage))
		}
	}
	
	const handleSalesMarginChange = (value, fieldName, formikValues, setFormikField) => {
		if(businessSettings.use_sales_margin) {
			const salesDifferenceBasedOnSalesMargin = (Number(formikValues.cost_price) * Number(value)) / 100
			const newSellingPrice = Number(formikValues.cost_price) + salesDifferenceBasedOnSalesMargin
			setFormikField("selling_price", newSellingPrice)
			setFormikField("selling_price_format", numberFormatter(newSellingPrice))
		}
	}

	return (
		<ModalLayoutStyled onClick={handleClose}>
			<CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={75}>
				<CloseSquare size={28} onClick={handleClose} />
				<Heading size={1.3} color="black">Create Product</Heading>

				<Form values={getProductFormValues()} enableReinitialize modalName={modalNames.EDIT_PRODUCIBLE_MODAL} onSubmit={handleSubmitFn}>
					<Fragment>
						<Input label="Name" placeholder="Enter product name" name="name"  />
						<Input type="number" formatNumber label="How many quantity do you want to produce" placeholder="Enter producing quantity" name="producing_quantity"  />
						
						<RawMaterialsSelection attachments={formValues?.productionIds} allSelectedProducts={formValues?.allSelectedProducts} />
						
						<Input optional label="SKU/Barcode" placeholder="Enter SKU/Barcode" name="sku"  />

						<Category setCategoryId={setCategoriesId} />
						<SubCategory allSubCategories={allSubCategories} setSubCategoryId={setCategoriesId} isSubCategorySuccess={isSubCategorySuccess} />
						
						{allNestedSubCat1?.results?.length > 0 && (
							<NestedSubCategory level={1} name="nested_category_1" isSubCategorySuccess={isNestedSubCat1Success} allSubCategories={allNestedSubCat1} setNestedSubCategoryId={setCategoriesId} />
						)}
						{allNestedSubCat2?.results?.length > 0 && (
							<NestedSubCategory level={2} name="nested_category_2" clearKeyName="nested_category_1" isSubCategorySuccess={isNestedSubCat2Success} allSubCategories={allNestedSubCat2} setNestedSubCategoryId={setCategoriesId} />
						)}
						{allNestedSubCat3?.results?.length > 0 && (
							<NestedSubCategory level={3} name="nested_category_3" clearKeyName="nested_category_2" isSubCategorySuccess={isNestedSubCat3Success} allSubCategories={allNestedSubCat3} />
						)}
						
						<Select
							SideInfo={AddUnitMeasurement}
							options={[...(unitMeasurement || []), ...unitMeasurementOptions]}
							displayKey="name"
							valueKey="name"
							label="Unit Measurement"
							placeholder="Select unit measurement"
							name="unit_measurement"
							displayName="unit_measurement"
						/>
						
						<Input
							type="number"
							formatNumber
							name="cost_price"
							label="Cost Price"
							placeholder="Enter cost price"
							onChange={handleCostPriceChange}
						/>
						
						{businessSettings?.use_sales_margin && (
							<Input
								optional
								formatNumber
								type="number"
								name="sales_margin"
								label="Sales margin Percentage"
								placeholder="Enter sales margin in percentage"
								onChange={handleSalesMarginChange}
							/>
						)}
						
						<Input
							type="number"
							formatNumber
							label="Selling Price"
							placeholder="Enter selling price"
							name="selling_price"
							onChange={handleSellingPriceChange}
						/>
						
						<FlexStyled />
						
						{showExtraMeasurement && <CustomerUnitMeasurement unitMeasurements={[...(unitMeasurement || []), ...unitMeasurementOptions]} /> }
						
						<Input
							optional
							type="number"
							formatNumber
							name="stock_unit"
							label="Available Stock Unit"
							placeholder="Enter the available stock unit"
						/>
						
						<Input
							optional
							type="number"
							formatNumber
							name="low_stock_level"
							label="Low Stock Level For Notification"
							placeholder="Enter low stock level"
						/>
						
						<MultiSelectInput
							className="fullWidth"
							displayName="name"
							selectedValues={formValues.stages || []}
							options={allProductionStages}
							setSelected={setSelectedStages}
							label="Product stages"
							placeholder="Select stages to produce product"
						/>

						{businessSettings.commission && (
							<CheckBox checked={isShowCommission} className="fullWidth" optional name="show_commission" afterCheck={setIsShowCommission} labelProp="Add commission to product" />
						)}
						
						{isShowCommission && (
							<Fragment>
								<Select
									options={commissionAndDiscountTypeOptions}
									displayKey="name"
									valueKey="name"
									label="Commission Type"
									placeholder="Enter commission type"
									name="commission_type"
									displayName="commission_type_name"
								/>
								<Input
									type="number"
									formatNumber
									label="Commission Value"
									placeholder="Enter value"
									name="commission_value"
								/>
							</Fragment>
						)}
						
						<CropImage optional width={400} height={600} className="fullWidth" name="image" placeholder="Select Product Image" />
						<FlexStyled>
							<Button isLoading={mutation.isLoading} text="Save" type="submit" />
						</FlexStyled>
					</Fragment>
				</Form>

			</CreateStoreModalStyled>
		</ModalLayoutStyled>
	)
}

const Category = memo(({ setCategoryId }) => {
	const { values, setFieldValue } = useFormikContext()
	const fromModalConnector = useFromModalConnector()
	const { isSuccess: isCategorySuccess, data: allCategories } = useCategoriesQuery()

	const handleSubCategoryQuery = (category) => {
		setCategoryId({
			categoryId: category.id,
			subCategoryId: "",
			nested_category_1: "",
			nested_category_2: ""
		})
		
		setFieldValue("nested_category_1", "")
		setFieldValue("nested_category_2", "")
		setFieldValue("nested_category_3", "")
		setFieldValue("nested_category_1_name", "")
		setFieldValue("nested_category_2_name", "")
		setFieldValue("nested_category_3_name", "")
	}
	
	const handleCreateCategory = () => {
		fromModalConnector({
			fromModalFormValues: values,
			toModalURL: "/dashboard/set-up/categories/",
			toModalName: modalNames.CREATE_CATEGORY_MODAL,
			fromModalName: modalNames.EDIT_PRODUCIBLE_MODAL,
		})
	}
	
	const CreateCategory = () => (
		<label className="underline" onClick={handleCreateCategory}>Create +</label>
	)
	
	return (
		<Select
			SideInfo={CreateCategory}
			displayKey="name"
			valueKey="id"
			name="category"
			displayName="category_name"
			label="Category"
			placeholder="Select Category"
			updateFn={handleSubCategoryQuery}
			options={isCategorySuccess ? allCategories.results : []}
		/>
	)
})


const SubCategory = memo(({ isSubCategorySuccess, allSubCategories, setSubCategoryId }) => {
	const { values, setFieldValue } = useFormikContext()
	const { toast } = useGlobalContext()
	const fromModalConnector = useFromModalConnector()
	
	const handleSubCategoryQuery = (subCategory) => {
		setSubCategoryId(prev => ({
			...prev,
			subCategoryId: subCategory.id,
			nested_category_1: "",
			nested_category_2: ""
		}))
		
		setFieldValue("nested_category_1", "")
		setFieldValue("nested_category_2", "")
		setFieldValue("nested_category_3", "")
		setFieldValue("nested_category_1_name", "")
		setFieldValue("nested_category_2_name", "")
		setFieldValue("nested_category_3_name", "")
	}

	const handleCreateSubCategory = () => {
		if(!values.category || !values.category_name) {
			toast.error("Please select category first")
			return
		}

		fromModalConnector({
			fromModalFormValues: values,
			fromModalName: modalNames.EDIT_PRODUCIBLE_MODAL,
			toModalName: modalNames.CREATE_SUBCATEGORY_MODAL,
			toModalURL: `/dashboard/set-up/categories/${values.category_name}/${values.category}`,
		})
	}
	
	const CreateSubCategory = () => (
		<label className="underline" onClick={handleCreateSubCategory}>Create +</label>
	)
	
	return (
		<Select
			optional
			valueKey="id"
			displayKey="name"
			name="sub_category"
			label="Sub-Category"
			clearCondition="category"
			displayName="sub_category_name"
			placeholder="Select sub-category"
			SideInfo={CreateSubCategory}
			updateFn={handleSubCategoryQuery}
			options={isSubCategorySuccess ? allSubCategories.results : []}
		/>
	)
})


const NestedSubCategory = memo(({ isSubCategorySuccess, allSubCategories, setNestedSubCategoryId, level, name, clearKeyName }) => {
	const { values, setFieldValue } = useFormikContext()
	
	useEffect(() => {
		setFieldValue(name, "")
		setFieldValue(`${name}_name`, "")
		
		if(level === MAX_NESTED_SUB_CATEGORY_LEVEL) return
		
		setNestedSubCategoryId(prev => ({
			...prev,
			[name]: "",
		}))
	}, [values[clearKeyName]])
	
	const handleSubCategoryQuery = (subCategory) => {
		if(level === MAX_NESTED_SUB_CATEGORY_LEVEL) return
		
		setNestedSubCategoryId && setNestedSubCategoryId(prev => ({ ...prev, [name]: subCategory.id }))
	}
	
	return (
		<Select
			optional
			valueKey="id"
			displayKey="name"
			name={name}
			label={`Sub-category ${Number(level) + 1}`}
			clearCondition={clearKeyName}
			displayName={`${name}_name`}
			updateFn={handleSubCategoryQuery}
			placeholder={`Select sub category ${Number(level) + 1}`}
			options={isSubCategorySuccess ? allSubCategories.results : []}
		/>
	)
})


const CustomerUnitMeasurement = memo(({ unitMeasurements }) => {
	const { setFieldValue, values } = useFormikContext()
	const fromModalConnector = useFromModalConnector()
	
	const handleCreateUnitMeasurement = () => {
		fromModalConnector({
			fromModalFormValues: values,
			toModalURL: "/dashboard/set-up/unit-measurement/",
			toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
			fromModalName: modalNames.EDIT_PRODUCIBLE_MODAL,
		})
	}
	
	const CreateUnitMeasurementComp = () => {
		return <label className="underline" onClick={handleCreateUnitMeasurement}>Create +</label>
	}
	
	const ProductionAttachmentForm = () => {
		const id = uuid()
		
		const SelectComp = () => (
			<Select
				SideInfo={CreateUnitMeasurementComp}
				options={unitMeasurements}
				displayKey="name"
				valueKey="name"
				label="Unit Measurement"
				placeholder="Select unit measurement"
				name={`unit_measurement_name_${id}`}
				displayName={`unit_measurement_display_${id}`}
			/>
		)
		
		const InputComp = () => (
			<Input
				type="number"
				formatNumber
				name={`unit_measurement_quantity_${id}`}
				label="Quantity"
				placeholder="Enter quantity per measurement"
			/>
		)
		
		const InputComp2 = () => (
			<Input
				type="number"
				formatNumber
				name={`unit_measurement_price_${id}`}
				label="Sales Price"
				placeholder="Enter sales price per measurement"
			/>
		)
		
		return { id, SelectComp, InputComp, InputComp2 }
	}
	
	const [moreProducts, setMoreProducts] = useState([])
	
	const addExtraProduct = () => {
		setMoreProducts([...moreProducts, ProductionAttachmentForm()])
	}
	
	const handleDelete = (id) => {
		const newProducts = moreProducts.filter(products => products.id !== id)
		setMoreProducts(newProducts)
		
		setFieldValue(`unit_measurement_name_${id}`, "")
		setFieldValue(`unit_measurement_display_${id}`, "")
		setFieldValue(`unit_measurement_quantity_${id}`, "")
		setFieldValue(`unit_measurement_price_${id}`, "")
	}
	
	return (
		<div className="fullWidth add_extra_3">
			<Fragment>
				<FlexStyled className="fullWidth three_form_cols space_between">
					<Select
						SideInfo={CreateUnitMeasurementComp}
						options={unitMeasurements}
						displayKey="name"
						valueKey="name"
						label="Unit Measurement"
						placeholder="Select unit measurement"
						name="unit_measurement_name"
						displayName="unit_measurement_display"
					/>
					
					<Input
						type="number"
						formatNumber
						name="unit_measurement_quantity"
						label="Quantity"
						placeholder="Enter quantity per measurement"
					/>
					
					<Input
						type="number"
						formatNumber
						name="unit_measurement_price"
						label="Sales Price"
						placeholder="Enter sales price per measurement"
					/>
				</FlexStyled>
				
				{moreProducts?.map(({ id, SelectComp, InputComp, InputComp2 }) => (
					<FlexStyled className="fullWidth three_form_cols" key={id}>
						<SelectComp />
						<InputComp />
						<InputComp2 />
						<Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
					</FlexStyled>
				))}
				
				<Button onClick={addExtraProduct} type="button" text="Add more measurement" className="small add_extra" small />
				<br/>
			</Fragment>
		
		</div>
	)
})


const RawMaterialsSelection = memo(({ attachments = [], allSelectedProducts = [] }) => {
	const { numberFormatter } = useDataTypeFormatter()
	const [searchProduct, setSearchProduct] = useState("")
	const { data, isLoading: isProductLoading } = useProductQuery({ search: searchProduct, type: "raw_material" })
	const { setFieldValue, values } = useFormikContext()
	const allSelectedProductsWithIDRef = useRef(allSelectedProducts)
	
	
	const handleSelectProduct = (product, id = "main") => {
		const filterProducts = allSelectedProductsWithIDRef.current.filter(productObj => productObj?.id !== id)
		allSelectedProductsWithIDRef.current = [...filterProducts, {id: id, product}]
		setFieldValue(`production_raw_material_measurement_${id}`, `${product.name} to produce ${numberFormatter(values.producing_quantity)} quantity of ${values.name} (in ${product.production_unit_measurement_name})`)
	}
	
	const handleCalculateRawMaterials = (quantity, id = "main") => {
		const product = allSelectedProductsWithIDRef.current.find(productObj => productObj.id === id)

		const raw_material = {
			product_name: product.product.name,
			product_id: product.product.id,
			qty: Number(quantity),
			type: "production_attachment",
			unit_measurement: product.product.production_unit_measurement_name
		}
		
		const quantityPerOne = Number(Number(quantity) / Number(values["producing_quantity"]))
		const message = `${quantityPerOne}-${product.product.production_unit_measurement_name} of ${product.product.name} is needed to make 1 ${values["name"]}`
		
		setFieldValue(`production_raw_material_${id}`, raw_material)
		setFieldValue(`raw_material_note_${id}`, message)
	}
	
	const RawMaterialForm = (newId) => {
		const id = newId || uuid()
		
		const SelectComp = ({ data, isProductLoading }) => (
			<Select
				valueKey="id"
				displayKey="name"
				name={`raw_material_name_${id}`}
				label={`Raw Material To Produce ${values["name"] || ""}`}
				displayName={`raw_material_name_display_${id}`}
				placeholder="Select raw material"
				options={data?.results}
				updateFn={(prod) => handleSelectProduct(prod, id)}
				updateQueryResult={setSearchProduct}
				isSearchLoading={isProductLoading}
			/>
		)
		
		const InputComp = ({ values }) => (
			<Input
				type="number"
				formatNumber
				name={`raw_material_quantity_${id}`}
				label={`Quantity of ${values[`production_raw_material_measurement_${id}`] || ""}`}
				placeholder="Enter quantity"
				onChange={(quantity) => handleCalculateRawMaterials(quantity, id)}
				clearCondition={`raw_material_name_${id}`}
			/>
		)
		
		return { id, SelectComp, InputComp }
	}
	
	const [moreProducts, setMoreProducts] = useState([])
	
	useEffect(() => {
		if(!!attachments && attachments.length > 0) {
			const attachmentStructure = attachments.map(id => {
				return RawMaterialForm(id)
			})
			
			setMoreProducts(attachmentStructure)
		}
	}, [attachments])
	
	const addExtraProduct = () => {
		setMoreProducts([...moreProducts, RawMaterialForm()])
	}
	
	const handleDelete = (id) => {
		const newProducts = moreProducts.filter(products => products.id !== id)
		setMoreProducts(newProducts)
		
		setFieldValue(`raw_material_name_${id}`, "")
		setFieldValue(`raw_material_name_display_${id}`, "")
		setFieldValue(`raw_material_quantity_${id}`, "")
		setFieldValue(`production_raw_material_${id}`, "")
	}
	
	return (
		<div className="fullWidth">
			<Fragment>
				{moreProducts?.map(({ id, SelectComp, InputComp }) => (
					<div key={id}>
						<FlexStyled className="fullWidth two_cols_with_trash_icon">
							<SelectComp data={data} isProductLoading={isProductLoading} />
							<InputComp values={values} />
							<Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id)} />
							
							<FlexStyled className="fullWidth production_raw_materials_preview">
								{values[`raw_material_note_${id}`] && (
									<Paragraph color="black">{values[`raw_material_note_${id}`]}</Paragraph>
								)}
							</FlexStyled>
						</FlexStyled>
					</div>
				))}
				
				<Button onClick={addExtraProduct} type="button" text="Add more raw materials" className="small" small />
				<br/>
			</Fragment>
		
		</div>
	)
})

export default EditProducibleProductModal