import {CloseSquare, Trash} from "iconsax-react";
import {Heading, Paragraph} from "../../../../styles/textStyles";
import {Button, CheckBox, Form, Input, Line, Select} from "../../../index";
import {FlexStyled, SpaceBetweenStyled} from "../../../../styles/utilStyles";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../../styles/modalStyles";
import {useAllStoresQuery} from "../../../../hooks/useServices/useStoreServices";
import useGlobalContext from "../../../../hooks/useContexts/useGlobalContext";
import {Fragment, memo, useState} from "react";
import {useFormikContext} from "formik";
import uuid from "react-uuid";
import useDataTypeFormatter from "../../../../hooks/useUtils/useDataTypeFormatter";
import {ContainerStyled} from "../../../../styles/DashboardStyles";


const DistributeMultipleProductModal = ({ onClose, handleSubmit, mutation, products = [] }) => {
  const { getBusinessId } = useGlobalContext()
  const businessId = getBusinessId()
  const { isSuccess, data: allStores } = useAllStoresQuery()
  
  const handleSubmission = (values) => {
    const results = products?.map(product => {
      return handleCostPriceDataFormatting(values, product)
    })
    
    console.log(results)
    handleSubmit(results)
  }
  
  const moveStores = (stores = []) => {
    return stores.filter(store => store.id !== businessId)
  }

  return (
    <ModalLayoutStyled onClick={onClose}>
      <CreateStoreModalStyled onClick={e => e.stopPropagation()}>
        <CloseSquare size={28} onClick={onClose} />
        <Heading size={1.3} color="black">Multiple Product Transfer</Heading>

        <Form values={{ qty: "", business: "", display_store_name: "" }} onSubmit={handleSubmission}>
          {products?.map((product, k) => (
            <Fragment key={product.id}>
              {k !== 0 && <Line/>}
              <ContainerStyled>
                <Paragraph color="black" bold size={1.2}>Product {k+1}: {product.name}</Paragraph>
                <br/>
                <ManageProductCostPrice product={product} />
              </ContainerStyled>
            </Fragment>
          ))}
          
          <Select
            required
            valueKey="id"
            name="business"
            label="Select Store"
            className="fullWidth"
            displayKey="store_name"
            displayName="display_store_name"
            options={isSuccess && moveStores(allStores)}
            placeholder="Select store to move products to"
          />
          
          <CheckBox name="move_sp" labelProp="Move products selling price to store?" optional/>
          
          <FlexStyled>
            <Button type="submit" text="Transfer" isLoading={mutation.isLoading} />
          </FlexStyled>
        </Form>
      </CreateStoreModalStyled>
    </ModalLayoutStyled>
  )
}


export default DistributeMultipleProductModal


const ManageProductCostPrice = ({ product }) => {
  const { currencyFormatter, numberFormatter } = useDataTypeFormatter()
  
  const costPricesArray = product?.cost_prices?.map(cost_price => ({
    ...cost_price,
    name: `${currencyFormatter(cost_price.cost_price)} [${numberFormatter(cost_price.quantity)} qty] ${!!cost_price.batch_number ? `- B.N: ${cost_price.batch_number}` : ""} `
  }))
  
  return (
    <Fragment>
      {!!costPricesArray && costPricesArray?.length > 0 && (
        <AddCostPriceToMove cost_prices={costPricesArray} product_id={product.id} />
      )}
  
      {(!costPricesArray || (!!costPricesArray && costPricesArray?.length < 1)) && (
        <Input type="number" name={`qty_${product.id}`} className="fullWidth" label="Quantity" placeholder="Enter quantity" />
      )}
    </Fragment>
  )
}



export const AddCostPriceToMove = memo(({ cost_prices = [], product_id }) => {
  const [selectedCostPrices, setSelectedCostPrices] = useState([])
  const { setFieldValue } = useFormikContext()
  
  const handleCostPriceSelect = (data, uuid = "main") => {
    const result = { id: data.id, uuid }
    setSelectedCostPrices([...selectedCostPrices, result])
  }
  
  const mapOnlyIds = () => {
    return selectedCostPrices?.map(price => price.id)
  }
  
  const SalesAttachmentForm = () => {
    const uid = uuid()
    const id = `${uid}_${product_id}`
    
    const SelectComp = () => (
      <Select
        valueKey="id"
        displayKey="name"
        name={`sales_attachment_name_${id}`}
        label="Product Cost Price"
        displayName={`sales_attachment_display_${id}`}
        placeholder="Select product cost price"
        options={cost_prices.filter(price => !mapOnlyIds().includes(price.id))}
        updateFn={(data) => handleCostPriceSelect(data, uid)}
      />
    )
    
    const InputComp = () => (
      <Input
        type="number"
        formatNumber
        name={`sales_attachment_quantity_${id}`}
        label="Quantity"
        placeholder="Enter quantity"
      />
    )
    
    return { id, SelectComp, InputComp, uid }
  }
  
  const [moreProducts, setMoreProducts] = useState([])
  
  const addExtraProduct = () => {
    setMoreProducts([...moreProducts, SalesAttachmentForm()])
  }
  
  const handleDelete = (id, uid) => {
    const selectedProd = selectedCostPrices.filter(prod => prod.uuid !== uid)
    setSelectedCostPrices(selectedProd)
    
    const newProducts = moreProducts.filter(products => products.id !== id)
    setMoreProducts(newProducts)
    
    setFieldValue(`sales_attachment_name_${id}`, "")
    setFieldValue(`sales_attachment_display_${id}`, "")
    setFieldValue(`sales_attachment_quantity_${id}`, "")
    setFieldValue(`sales_attachment_cost_${id}`, "")
  }
  
  return (
    <div className="fullWidth">
      <Fragment>
        <FlexStyled className="fullWidth">
          <Select
            valueKey="id"
            displayKey="name"
            name={`sales_attachment_${product_id}`}
            label="Product Cost Price"
            displayName={`sales_attachment_display_${product_id}`}
            placeholder="Select product cost price"
            options={cost_prices}
            updateFn={(data) => handleCostPriceSelect(data)}
          />
          
          <Input
            type="number"
            formatNumber
            name={`sales_attachment_quantity_${product_id}`}
            label="Quantity"
            placeholder="Enter quantity"
          />
        </FlexStyled>
        
        {moreProducts?.map(({ id, SelectComp, InputComp, uid }) => (
          <SpaceBetweenStyled className="fullWidth" key={id}>
            <SelectComp />
            <InputComp />
            <Trash size={20} color="red" className="supplier_delete" onClick={handleDelete.bind(this, id, uid)} />
          </SpaceBetweenStyled>
        ))}
  
        {cost_prices.length > selectedCostPrices.length && (
          <Button onClick={addExtraProduct} type="button" text="Select product batch" className="small" small />
        )}
        <br/>
      </Fragment>
    
    </div>
  )
})


export const handleCostPriceDataFormatting = (values, product) => {
  let totalQuantity = 0
  const formValues = { ...values }
  const sales_attachment = values[`sales_attachment_${product.id}`]
  const sales_attachment_quantity = values[`sales_attachment_quantity_${product.id}`]
  
  if(!sales_attachment) {
    return {
      qty: Number(values[`qty_${product.id}`]),
      quantity: Number(values[`qty_${product.id}`]),
      business: values.business,
      product: product.id,
      move_sp: values?.move_sp || false
    }
  }
  
  totalQuantity += Number(sales_attachment_quantity)
  const products = [
    { id: sales_attachment, quantity: Number(sales_attachment_quantity) }
  ]
  
  
  for (const key in formValues) {
    if(key.slice(0, 21) === "sales_attachment_name") {
      
      const uuid = key.split("_")[3]
      
      const id = formValues[`sales_attachment_name_${uuid}_${product.id}`]
      const quantity = Number(formValues[`sales_attachment_quantity_${uuid}_${product.id}`])
      
      if(!id || !quantity) continue
      
      products.push({ id, quantity })
      totalQuantity += quantity || 0
      
      delete formValues[`sales_attachment_name_${uuid}`]
      delete formValues[`sales_attachment_display_${uuid}`]
      delete formValues[`sales_attachment_quantity_${uuid}`]
      delete formValues[`sales_attachment_cost_${uuid}`]
      delete formValues[`sales_attachment_quantity_${uuid}_format`]
      delete formValues[`sales_attachment_cost_${uuid}_format`]
    }
  }
  
  return {
    move_sp: values?.move_sp || false,
    business: values?.business,
    product: product.id,
    cost_price_ids: products,
    qty: totalQuantity
  }
}