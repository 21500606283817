import {Fragment, memo} from "react";
import {CloseSquare,} from "iconsax-react";
import {Heading} from "../../../styles/textStyles";
import {FlexStyled} from "../../../styles/utilStyles";
import {Button, Form, Input, Select} from "../../index";
import {useCategoriesQuery} from "../../../hooks/useServices/useCategoryServices";
import {CreateStoreModalStyled, ModalLayoutStyled} from "../../../styles/modalStyles";
import CropImage from "../CropImage";
import {useFormikContext} from "formik";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {useFromModalConnector} from "../../../hooks/useModalConnector";
import modalNames from "../../../hooks/useModalConnector/modalNames";
import {globalReducerActions} from "../../../reducers/globalReducer";
import {
	useProductionUnitMeasurementQuery
} from "../../../hooks/useServices/useProductionServices/useProductionUnitMeasurementServices";


const EditProductionRawMaterialModal = ({ onClose, handleSubmit, mutation, formValues }) => {
	const { globalReducer } = useGlobalContext()
	
	const { data: productionUnitMeasurement } = useProductionUnitMeasurementQuery()
	
	const handleClose = () => {
		onClose()
		globalReducer({
			type: globalReducerActions.CLEAR_MODAL_CONNECTOR
		})
	}
	
	const handleSubmitFn = (formValues) => {
		handleSubmit({ ...formValues, unit_increment: 1, type: "raw_material" })
	}
	
	const AddUnitMeasurement = () => {
		const fromModalConnector = useFromModalConnector()
		const { values } = useFormikContext()
		
		const handleCreateProductionUnitMeasurement = () => {
			fromModalConnector({
				fromModalFormValues: values,
				toModalURL: "/dashboard/production/unit-measurements/",
				toModalName: modalNames.CREATE_PRODUCTION_UNIT_MEASUREMENT_MODAL,
				fromModalName: modalNames.EDIT_RAW_MATERIAL_MODAL,
			})
		}
		
		return (
			<label className="underline" onClick={handleCreateProductionUnitMeasurement}>Create +</label>
		)
	}
	
	
	return (
		<ModalLayoutStyled onClick={handleClose}>
			<CreateStoreModalStyled onClick={e => e.stopPropagation()} $width={70}>
				<CloseSquare size={28} onClick={handleClose} />
				<Heading size={1.3} color="black">Edit Raw Material</Heading>
				
				<Form values={formValues} modalName={modalNames.EDIT_RAW_MATERIAL_MODAL} onSubmit={handleSubmitFn}>
					<Fragment>
						<Input label="Name" placeholder="Enter product name" name="name"  />
						<Input optional label="SKU/Barcode" placeholder="Enter SKU/Barcode" name="sku"  />
						
						<Category />
						
						<Select
							SideInfo={AddUnitMeasurement}
							options={productionUnitMeasurement}
							displayKey="name"
							valueKey="id"
							label="Measurement unit you buy with"
							placeholder="Select unit measurement"
							name="purchase_unit_measurement"
							displayName="purchase_unit_measurement_name"
						/>
						
						<Select
							SideInfo={AddUnitMeasurement}
							options={productionUnitMeasurement}
							displayKey="name"
							valueKey="id"
							label="Measurement unit you produce with"
							placeholder="Select unit measurement"
							name="production_unit_measurement"
							displayName="production_unit_measurement_name"
						/>
						
						<CompareUnitMeasurements />
						
						<Input
							optional
							type="number"
							formatNumber
							name="low_stock_level"
							label="Low stock level for notification"
							placeholder="Enter low stock level"
						/>
						
						<Input
							type="number"
							formatNumber
							name="cost_price"
							label="Cost Price"
							placeholder="Enter cost price"
						/>
						
						<AvailableStockUnit />
						
						<CropImage optional width={400} height={600} className="fullWidth" name="image" placeholder="Select Raw Material Image" />
						<FlexStyled>
							<Button isLoading={mutation.isLoading} text="Save" type="submit" />
						</FlexStyled>
					</Fragment>
				</Form>
			
			</CreateStoreModalStyled>
		</ModalLayoutStyled>
	)
}

const Category = memo(() => {
	const { values } = useFormikContext()
	const fromModalConnector = useFromModalConnector()
	const { isSuccess: isCategorySuccess, data: allCategories } = useCategoriesQuery()
	
	const handleCreateCategory = () => {
		fromModalConnector({
			fromModalFormValues: values,
			toModalURL: "/dashboard/set-up/categories/",
			toModalName: modalNames.CREATE_CATEGORY_MODAL,
			fromModalName: modalNames.CREATE_RAW_MATERIAL_MODAL,
		})
	}
	
	const CreateCategory = () => (
		<label className="underline" onClick={handleCreateCategory}>Create +</label>
	)
	
	return (
		<Select
			SideInfo={CreateCategory}
			displayKey="name"
			valueKey="id"
			name="category"
			displayName="category_name"
			label="Category"
			placeholder="Select Category"
			options={isCategorySuccess ? allCategories.results : []}
		/>
	)
})


const CompareUnitMeasurements = () => {
	const { values } = useFormikContext()
	
	return (
		(!!values["purchase_unit_measurement"] && !!values["production_unit_measurement"]) && (
			<Input
				type="number"
				formatNumber
				name="warehouse_to_production_count"
				label={`How many ${values["production_unit_measurement_name"]} makes 1 ${values["purchase_unit_measurement_name"]}`}
				placeholder={`Enter quantity of ${values["production_unit_measurement_name"]}`}
			/>
		)
	)
}

const AvailableStockUnit = () => {
	const { values } = useFormikContext()
	
	return (
		<Input
			optional
			type="number"
			formatNumber
			name="stock_unit"
			label={`Available Stock Unit in ${values["purchase_unit_measurement_name"] || ""} `}
			placeholder="Enter the available stock unit"
		/>
	)
}


export default EditProductionRawMaterialModal