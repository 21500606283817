import uuid from "react-uuid";

export const handleFormatCustomUnitMeasurementForFormSubmission = (formValues = {}) => {
  const values = {...formValues}
  const {unit_measurement_display, unit_measurement_quantity, unit_measurement_price} = values
  
  const unit_measurement_arr = []
  
  if (!!unit_measurement_display) {
    unit_measurement_arr.push({
      name: unit_measurement_display,
      base_quantity: unit_measurement_quantity,
      selling_price: unit_measurement_price
    })
  }
  
  delete values["unit_measurement_name"]
  delete values["unit_measurement_display"]
  delete values["unit_measurement_quantity"]
  delete values["unit_measurement_price"]
  delete values["unit_measurement_quantity_format"]
  delete values["unit_measurement_price_format"]
  
  for (const key in formValues) {
    if(key.slice(0, "unit_measurement_name".length) === "unit_measurement_name") {
      const uuid = key.split("_")[3]
  
      const name = formValues[`unit_measurement_display_${uuid}`]
      const base_quantity = formValues[`unit_measurement_quantity_${uuid}`]
      const selling_price = formValues[`unit_measurement_price_${uuid}`]
  
      if (!name || !base_quantity || !selling_price) continue
  
      const unitMeasurement = {name, base_quantity, selling_price}
      const percentageDiff = calculatePercentageDifferenceOfUnitMeasurementSellingPrice(unitMeasurement, values.selling_price)
      unit_measurement_arr.push({...unitMeasurement, unit_price_difference: percentageDiff})
  
      delete values[`unit_measurement_name_${uuid}`]
      delete values[`unit_measurement_display_${uuid}`]
      delete values[`unit_measurement_quantity_${uuid}`]
      delete values[`unit_measurement_price_${uuid}`]
      delete values[`unit_measurement_quantity_${uuid}_format`]
      delete values[`unit_measurement_price_${uuid}_format`]
    }
  }
  
  return { ...values, meta_measurement: unit_measurement_arr }
}


export const handleFormatSalesAttachmentForFormSubmission = (formValues = {}) => {
  if(!formValues["show_sales_attachment"]) return formValues
  
  const values = { ...formValues }
  const { sales_attachment, sales_attachment_display, sales_quantity, product_attachment = [] } = values
  
  const allAttachments = [...product_attachment]
  
  if(!!sales_attachment) {
    allAttachments.push({ product_name: sales_attachment_display, product_id: sales_attachment, qty: sales_quantity, type: "sales_attachment" })
  }
  
  delete formValues["sales_quantity"]
  delete formValues["sales_attachment"]
  delete formValues["sales_attachment_display"]
  
  for (const key in formValues) {
    if(key.slice(0, "sales_attachment_name".length) === "sales_attachment_name") {
      
      const uuid = key.split("_")[3]
      
      const product_id = formValues[`sales_attachment_name_${uuid}`]
      const product_name = formValues[`sales_attachment_display_${uuid}`]
      const qty = Number(formValues[`sales_attachment_quantity_${uuid}`])
      
      if(!product_id || !product_name || !qty) continue
      allAttachments.push({ product_id, product_name, qty, type: "sales_attachment" })

      delete values[`sales_attachment_name_${uuid}`]
      delete values[`sales_attachment_display_${uuid}`]
      delete values[`sales_attachment_quantity_${uuid}`]
      delete values[`sales_attachment_quantity_${uuid}_format`]
    }
  }
  
  return { ...values, product_attachment: allAttachments }
}


export const handleFormatProductAttachmentForFormSubmission = (formValues = {}) => {
  if(!formValues["show_production_attachment"]) return formValues
  
  const values = { ...formValues }
  const { production_attachment, production_attachment_display, production_quantity, product_attachment = [] } = values
  
  const allAttachments = [...product_attachment]
  
  if(!!production_attachment) {
    allAttachments.push({ product_name: production_attachment_display, product_id: production_attachment, qty: production_quantity, type: "production_attachment" })
  }
  
  delete formValues["production_attachment"]
  delete formValues["production_attachment_display"]
  delete formValues["production_quantity"]
  
  for (const key in formValues) {
    if(key.slice(0, "production_attachment_name".length) === "production_attachment_name") {
      const uuid = key.split("_")[3]
      
      const product_id = formValues[`production_attachment_name_${uuid}`]
      const product_name = formValues[`production_attachment_display_${uuid}`]
      const qty = Number(formValues[`production_attachment_quantity_${uuid}`])
  
      if(!product_id || !product_name || !qty) continue
      allAttachments.push({ product_id, product_name, qty, type: "production_attachment" })
      
      delete values[`production_attachment_name_${uuid}`]
      delete values[`production_attachment_display_${uuid}`]
      delete values[`production_attachment_quantity_${uuid}`]
      delete values[`production_attachment_quantity_${uuid}_format`]
    }
  }
  
  return { ...values, product_attachment: allAttachments }
}


export const handleFormatCustomUnitMeasurementForFormEditing = (formValues = {}, currencyFormatter) => {
  if(!formValues?.meta_measurement || formValues?.meta_measurement?.length < 1) return formValues
  
  const values = { ...formValues }
  
  const customMeasurementIds = formValues?.meta_measurement?.map(measurement => {
    const id = uuid()

    values[`unit_measurement_name_${id}`] = measurement.name
    values[`unit_measurement_display_${id}`] = measurement.name
    values[`unit_measurement_quantity_${id}`] = measurement.base_quantity
    values[`unit_measurement_price_${id}`] = measurement.selling_price
    values[`unit_measurement_quantity_${id}_format`] = measurement.base_quantity
    values[`unit_measurement_price_${id}_format`] = measurement.selling_price
    values[`unit_measurement_cost_price_${id}`] = `Cost Price: ${currencyFormatter(Number(measurement.base_quantity) * Number(formValues.cost_price))} `
  
    return id
  })
  
  const customMeasurementIdsForFaulty = formValues?.faulty_meta_measurement?.map(measurement => {
    const id = uuid()
    
    values[`unit_measurement_name_${id}`] = measurement.name
    values[`unit_measurement_display_${id}`] = measurement.name
    values[`unit_measurement_quantity_${id}`] = measurement.base_quantity
    values[`unit_measurement_price_${id}`] = measurement.selling_price
    values[`unit_measurement_quantity_${id}_format`] = measurement.base_quantity
    values[`unit_measurement_price_${id}_format`] = measurement.selling_price
    values[`unit_measurement_cost_price_${id}`] = `Cost Price: ${currencyFormatter(Number(measurement.base_quantity) * Number(formValues.cost_price))} `
    
    return id
  })
  
  return {...values, unit_measurement_ids: customMeasurementIds, faulty_measurement_ids: customMeasurementIdsForFaulty}
}


export const handleFormatProductionAttachmentForFormSubmission = (values = {}) => {
  const formValues = { ...values }
  const product_attachment = []
  
  const mainRawMaterial = formValues["production_raw_material_main"]
  
  if(typeof mainRawMaterial === "object" && mainRawMaterial.product_name && mainRawMaterial.product_id) {
    product_attachment.push(formValues["production_raw_material_main"])
  }
  
  delete formValues[`production_raw_material_main`]
  delete formValues[`raw_material_note_main`]
  delete formValues[`raw_material_quantity`]
  delete formValues[`raw_material_quantity_format`]
  delete formValues[`raw_material_name_display`]
  delete formValues[`raw_material_name`]
  delete formValues[`production_raw_material_measurement_main`]
  
  
  for (const key in formValues) {
    if(key.slice(0, "production_raw_material".length) === "production_raw_material") {
      const uuid = key.split("_")[3]
      if(!uuid || uuid.length < 17) continue
      
      const raw_material = formValues[`production_raw_material_${uuid}`]
      if(typeof raw_material !== "object" || !raw_material.product_name || !raw_material.product_id) continue
      
      product_attachment.push(formValues[`production_raw_material_${uuid}`])
      
      delete formValues[`production_raw_material_${uuid}`]
      delete formValues[`raw_material_note_${uuid}`]
      delete formValues[`raw_material_quantity_${uuid}`]
      delete formValues[`raw_material_quantity_${uuid}_format`]
      delete formValues[`raw_material_name_display_${uuid}`]
      delete formValues[`raw_material_name_${uuid}`]
      delete formValues[`production_raw_material_measurement_${uuid}`]
  
    }
  }
  
  return { ...formValues, product_attachment }
}


export const handleFormatProductionAttachmentForFormEditing = (formValues) => {
  if(!formValues.product_attachment || formValues.product_attachment.length === 0) return formValues
  
  const values = { ...formValues }
  
  const productionAttachment = values.product_attachment.filter(attachments => attachments.type === "production_attachment" )
  const allSelectedProducts = []
  
  const productionIds = productionAttachment.map(item => {
    const id = uuid()
    
    values[`raw_material_name_${id}`] = item.product_id
    values[`raw_material_name_display_${id}`] = item.product_name
    values[`raw_material_quantity_${id}`] = item.qty
    values[`raw_material_quantity_${id}_format`] = item.qty
    values[`production_raw_material_measurement_${id}`] = `${item.product_name} to produce ${Number(values.producing_quantity)} quantity of ${values.name} (in ${item.unit_measurement})`
      
      // item.unit_measurement
    
    const quantityPerOne = Number(Number(item.qty) / Number(values["producing_quantity"]))
    values[`raw_material_note_${id}`] = `${quantityPerOne}-${item.unit_measurement} of ${item.product_name} is needed to make 1 ${values["name"]}`
  
    values[`production_raw_material_${id}`] = {
      product_name: item.product_name,
      product_id: item.product_id,
      qty: Number(item.qty),
      type: "production_attachment",
      unit_measurement: item.unit_measurement
    }
  
    allSelectedProducts.push({
      id: id, product: {
        name: item.product_name,
        id: item.product_id,
        production_unit_measurement_name: item.unit_measurement,
      }
    })
  
    return id
  })
  
  return {...values, productionIds, allSelectedProducts}
}

export const handleFormatProductForProductLookUp = (data, isOtherProductLookUpActive, isUpdate) => {
  const formValues = {...data}
  const cleanUp = (allKeys = []) => {
    [...allKeys, "sku2", "sku3", "sku4", "sku5"].forEach(lookup => {
      formValues[lookup] = null
    })
  }
  
  // if creating/editing product but no lookup is activated
  if (!isOtherProductLookUpActive) {
    cleanUp(["sku1"])
    return formValues
  }
  
  cleanUp()
  // editing of product does not have sku1 field, all sku fields uses sku_${id} so start writing from sku1 down to sku5
  // creating of product already have the field sku1 so skip that and start writing from sku2
  
  let extra_sku = ""
  
  if (!!formValues["sku1"]) extra_sku = formValues["sku1"]
  
  for (const key in formValues) {
    if (key.slice(0, 4) === "sku_") {
      if (extra_sku.length === 0) {
        extra_sku = formValues[key].trim()
      } else {
        extra_sku = `${extra_sku}, ${formValues[key].trim()}`
      }
      
      delete formValues[key] // delete sku field with uuid
    }
  }
  
  return {...formValues, extra_sku}
}

export const calculatePercentageDifferenceOfUnitMeasurementSellingPrice = (unitMeasurement = {}, sellingPricePerBaseUnit) => {
  const expectedSellingPriceForUnitMeasurement = Number(unitMeasurement.base_quantity) * Number(sellingPricePerBaseUnit)
  const percentageDiff = (((expectedSellingPriceForUnitMeasurement - Number(unitMeasurement.selling_price)) / Number(unitMeasurement.selling_price)) * 100).toFixed(2)
  return Number(percentageDiff)
}