import {Line} from "../index";
import {BackSquare, Edit, Eye, Trash} from "iconsax-react";
import {useTheme} from "styled-components";
import useTableValueTransformer from "../../hooks/useUtils/useTableValueTransformer";
import {TableItemActionStyled, TableItemStyled} from "../../styles/TableStyles";
import {displayTableItemWithStatus} from "../../utils/table";
import {useActionPermission} from "../../hooks/usePermissions";
import {Heading} from "../../styles/textStyles";
import {NO_OF_RESULTS_FROM_BACKEND} from "../../utils/constants";
import {Fragment} from "react";
import useGlobalContext from "../../hooks/useContexts/useGlobalContext";
import {actionPermissionEnum} from "../../hooks/usePermissions/useActionPermission";


const TableItemWithDeleteAndEditAction = ({
                                            data,
                                            onClick,
                                            actionPosition,
                                            keys,
                                            editAction,
                                            deleteAction,
                                            unDeleteAction,
                                            statusPosition = 99,
                                            editPermission,
                                            deletePermission,
                                            unDeletePermission,
                                            pageCount,
                                            count,
                                            noAction
                                          }) => {
  const theme = useTheme()
  const { isOffline, toast } = useGlobalContext()
  const tableData = useTableValueTransformer(data, keys)
  const showEditButton = useActionPermission(editPermission)
  const showDeleteButton = useActionPermission(deletePermission)
  const showUnDeleteButton = useActionPermission(unDeletePermission || actionPermissionEnum.NO_DISPLAY)
  
  const handleEdit = (data) => {
    if(isOffline) {
      toast.error("You cannot edit when offline", "offline")
      return
    }
    
    editAction?.(data)
  }
  
  const handleDelete = (data) => {
    if(isOffline) {
      toast.error("You cannot delete when offline", "offline")
      return
    }
    
    deleteAction?.(data)
  }
  
  const handleUnDelete = (data) => {
    if (isOffline) {
      toast.error("You cannot un-delete when offline", "offline")
      return
    }
    
    unDeleteAction?.(data)
  }


  return (
    <TableItemStyled onClick={onClick && onClick.bind(this, data)}>
      <td className="counter">
        <Heading color="black" thin size={1}>{(count+1) + ((pageCount-1) * NO_OF_RESULTS_FROM_BACKEND)}</Heading>
      </td>
      {tableData.map((item, k) => displayTableItemWithStatus(item, k, statusPosition))}

      <TableItemActionStyled position={actionPosition} onClick={e => e.stopPropagation()}>
        {(!noAction || !isOffline) && (
          <Fragment>
            {onClick && <Eye color={theme.gray} onClick={() => onClick?.(data)}/> }
            {showEditButton && <Edit color={theme.success} onClick={() => handleEdit(data)} />}
            {showDeleteButton && <Trash color={theme.error} onClick={() => handleDelete(data)} />}
            {showUnDeleteButton && <BackSquare color={theme.success} onClick={() => handleUnDelete(data)}/>}
          </Fragment>
        )}
      </TableItemActionStyled>
      <Line />
    </TableItemStyled>
  )
}

export default TableItemWithDeleteAndEditAction