import {
  Chips,
  InvoiceCheckOutModal,
  Line,
  OrderDetailsCard,
  PageHeader,
  ReceiptModal,
  RefundBalanceModal,
  ReturnOrderCart,
  ScreenLoading,
  Table,
  TableItemOrderAndWIP,
  TableItemWithEyeAction,
  TableItemWithStatus
} from "../../../components";
import {
  CustomerDataStyled,
  OrdersTableStyled,
  SingleOrderHeaderStyled,
  SingleOrderLayoutStyled
} from "../../../styles/singleOrderStyles";

import {Fragment, memo, useReducer, useState} from "react";
import {ArrowLeft, Copy} from "iconsax-react";
import {TiPrinter} from "react-icons/ti"
import {AiOutlineFilePdf} from "react-icons/ai"
import {useTheme} from "styled-components";
import {Link} from "react-router-dom";
import {Heading, Paragraph} from "../../../styles/textStyles";
import useGlobalContext from "../../../hooks/useContexts/useGlobalContext";
import {FlexStyled, SpaceBetweenStyled} from "../../../styles/utilStyles";
import useSingleGroupOrderData from "../../../hooks/useUtils/useSingleGroupOrderData";
import {
  useCancelSingleGroupOrderByGroupIdMutation,
  useConfirmGroupOrderByIdMutation,
  useDisburseOrderMutation,
  usePartialPayForGroupOrderMutation,
  useRefundForGroupOrderMutation,
  useSingleGroupByBusinessIdAndGroupIdQuery
} from "../../../hooks/useServices/useOrderServices";
import useModal from "../../../hooks/useModal";
import DisburseOrderModal from "../../../components/Modal/OrderModals/DIsburseOrderModal";
import tableHeadersAndValueKeys from "../../../data/tableHeadersAndValueKeys";
import NoQueryData from "../../../components/Utils/NoQueryData";
import {getOrderStatus} from "../../../utils/table";
import PayBalanceModal from "../../../components/Modal/SellModals/PayBalanceModal";
import {handleSplitOrPartialPaymentMethod} from "../../../utils/formatForms/checkout";
import useDataTypeFormatter from "../../../hooks/useUtils/useDataTypeFormatter";
import PaymentModal from "../../../components/Modal/PaymentModal";
import {actionsPermissions, routesPermissions} from "../../../data/permissions";
import {useCustomerByIdQuery} from "../../../hooks/useServices/useCustomerServices";
import {IoMdCheckmarkCircleOutline} from "react-icons/io"
import {currencyFormatterFn} from "../../../utils/dataTypeFormatter";
import sellReducer, {sellReducerInitialValues} from "../../../reducers/sellReducer";
import {SellProvider} from "../../../providers/SellProvider";
import {ModalLayoutStyled} from "../../../styles/modalStyles";
import useGroupOrderIdParams from "../../../hooks/useUtils/useGroupOrderIdParams";
import {useDownloadReceiptMutation} from "../../../hooks/useServices/useReportServices";
import TableSkeleton from "../../../components/Skeleton/TableSkeleton";
import {ContainerStyled} from "../../../styles/DashboardStyles";
import {useActionPermissionFn} from "../../../hooks/usePermissions/useActionPermission";
import {addModifyOrderToLocalStorage} from "../../../utils/sell";
import {handleCopyToClipBoard} from "../../../utils";
import {Tooltip} from "react-tooltip";
import {paymentMethodEnum} from "../../../data/selectField/paymentMethodsList";
import lodash from "lodash"
import {useDesktopPartialPayForOfflineMutation} from "../../../hooks/useDesktop/useDesktopOfflineMutation";
import {formatDatetimeForBackend} from "../../../utils/desktopUtils/desktopSyncUtils";
import {useUserProfile} from "../../../hooks/useServices/userHooks";
import ReturnedOrderReceipt from "../../../components/Modal/ReceiptModal/ReturnedOrderReceipt";
import PaymentRecordReceipt from "../../../components/Modal/ReceiptModal/PaymentRecordReceipt";


const SingleOrder = () => {
  const theme = useTheme()
  const {data: userProfile} = useUserProfile()
  const actionPermissionFn = useActionPermissionFn()
  const groupOrderId = useGroupOrderIdParams()
  
  const {navigate, toast, isOffline} = useGlobalContext()
  const {
    disbursedOrderTable,
    splitPaymentRecordTable,
    returnedOrdersLogTable,
    orderProfitLogTable,
    refundLogTable
  } = tableHeadersAndValueKeys
  const {currencyFormatter, numberFormatter} = useDataTypeFormatter()
  
  const {
    isSuccess: isSingleOrderGroupSuccess,
    isLoading: isSingleGroupOrderLoading,
    data: singleOrderGroup,
    status,
    refetch
  } = useSingleGroupByBusinessIdAndGroupIdQuery()
  const {
    bankDetails,
    orderDetails,
    staffDetails,
    customerDetails,
    summary
  } = useSingleGroupOrderData(singleOrderGroup?.results)
  
  const {} = useCustomerByIdQuery(singleOrderGroup?.results?.[0]?.customer?.id)
  
  const [showReceipt, setShowReceipt] = useModal()
  const [showPayModal, setShowPayModal] = useModal()
  const [showPaymentReceipt, setShowPaymentReceipt] = useModal()
  const [showRefundModal, setShowRefundModal] = useModal()
  const [showReturnedReceipt, setShowReturnedReceipt] = useModal()
  
  const [showInvoiceCheckoutModal, setShowInvoiceCheckoutModal] = useModal()
  const [showReturnOrderCart, setShowReturnOrderCart] = useModal()
  const [showOvaloopTransferModal, setShowOvaloopTransferModal] = useModal()
  const [showDistributeProduct, setShowDisburseOrder] = useModal()
  const [showConfirmBankTransferModal, setShowConfirmBankTransferModal] = useModal()
  const disburseOrderMutation = useDisburseOrderMutation({successFn: setShowDisburseOrder})
  const payBalanceMutation = usePartialPayForGroupOrderMutation({successFn: setShowPayModal})
  const refundMutation = useRefundForGroupOrderMutation({successFn: setShowRefundModal})
  const payBalanceOfflineMutation = useDesktopPartialPayForOfflineMutation({successFn: setShowPayModal})
  
  
  const cancelOrderMutation = useCancelSingleGroupOrderByGroupIdMutation({
    successFn: () => {
    }
  })
  const downloadReceiptMutation = useDownloadReceiptMutation({
    successFn: () => {
    }
  })
  
  const confirmGroupOrderMutation = useConfirmGroupOrderByIdMutation({
    successFn: setShowConfirmBankTransferModal
  })
  
  
  const handleDisburseOrder = (values) => {
    const groupOrderUUID = singleOrderGroup?.results?.[0]?.group_order?.id
    disburseOrderMutation.mutate({order_id: groupOrderUUID, ...values})
  }
  
  const getAllDisbursedOrder = () => {
    const orderDisbursed = singleOrderGroup?.results?.filter(order => !!order.disbursed === true)
    
    return orderDisbursed?.map(order => ({ ...order, ...order.disbursed }))
  }
  
  const handleConfirmedOrder = (values) => {
    const groupOrderUUID = singleOrderGroup?.results?.[0]?.group_order?.id
    confirmGroupOrderMutation.mutate({
      id: groupOrderUUID,
      ...(!!values.payment_date && {payment_date: formatDatetimeForBackend(values.payment_date)})
    })
  }
  
  const handleDownloadReceipt = () => {
    downloadReceiptMutation.mutate(singleOrderGroup?.results?.[0]?.group_id)
  }
  
  const handlePayBalance = (values) => {
    values.pay_mode = "split"
  
    const total = Number(singleOrderGroup?.results?.[0]?.balance)
  
    if (values.splitOrPartialPaymentAmount > total) {
      toast.error("Partial amount cannot be greater than total payable")
      return
    }
  
    const newValues = {
      ...values,
      pay_mode: "partial",
      ...(!!values.payment_date && {payment_date: formatDatetimeForBackend(values.payment_date)})
    }
  
    const formattedValues = handleSplitOrPartialPaymentMethod(newValues)
    const groupOrderUUID = singleOrderGroup?.results?.[0]?.group_order?.id
  
    if (singleOrderGroup?.results?.[0]?.is_offline) {
      payBalanceOfflineMutation.mutate({...formattedValues, groupOrderId: groupOrderUUID})
      return
    }
  
    payBalanceMutation.mutate({...formattedValues, groupOrderId: groupOrderUUID})
  }
  
  const isOrderStatusConfirmed = () => {
    return singleOrderGroup?.results?.[0]?.group_order?.status === "payment_confirmed"
  }
  
  const isGroupOrderStatusCancelledOrReturned = () => {
    return ["cancelled", "returned"].includes(singleOrderGroup?.results?.[0]?.group_order?.status)
  }
  
  const isPayModeTransferAndNotConfirmed = () => {
    if (isGroupOrderStatusCancelledOrReturned()) return false
  
    const allowed_pay_modes = ["transfer", "others", "pay_on_delivery"]
    return (
      !singleOrderGroup?.results?.[0]?.paid && allowed_pay_modes.includes(singleOrderGroup?.results?.[0]?.pay_mode)
    )
  }
  
  const isOvaloopTransferAndNotConfirmed = () => {
    return (!singleOrderGroup?.results?.[0]?.paid && singleOrderGroup?.results?.[0]?.pay_mode === "ovaloop_transfer")
  }
  
  const isOrderInvoiceAndNotPaid = () => {
    return (singleOrderGroup?.results?.[0]?.invoice && singleOrderGroup?.results?.[0]?.pay_mode === "invoice" && singleOrderGroup?.results?.[0]?.balance > 0)
  }
  
  const isPayModeCredit = () => {
    if (isOrderInvoiceAndNotPaid()) return
    if (isGroupOrderStatusCancelledOrReturned()) return
    return singleOrderGroup?.results?.[0]?.balance > 0
  }
  
  const isRefundAvailable = () => {
    return singleOrderGroup?.results?.[0]?.balance < 0
  }
  
  const unDisbursedItems = () => {
    return singleOrderGroup?.results?.filter(order => !!order?.disbursed !== true)
  }
  
  const handleCancelOrder = () => {
    const groupOrderUUID = singleOrderGroup?.results?.[0]?.group_order?.id
    toast.confirm(
      "Are you sure you want to cancel this order?",
      () => cancelOrderMutation.mutate(groupOrderUUID)
    )
  }

  const splitOrPartialPaymentTransactions = () => {
    const transactions = singleOrderGroup?.results?.[0]?.bulk_details?.pay_log || singleOrderGroup?.results?.[0]?.bulk_details?.split || []
    const filtered = transactions.filter(transaction => Number(transaction.amount) > 0)
  
    const data = filtered.map(item => ({
      ...item,
      method: lodash.capitalize(item.method || ""),
      bank_and_account: item.bank ? `${item.bank} ${!!item.bank_account ? `(${item.bank_account})` : ''}` : `N/A`
    }))
  
    return data.sort((a, b) => new Date(a.date) - new Date(b.date))
  }
  
  const modifiedLogs = () => {
    const allOrders = singleOrderGroup?.results?.[0]?.group_order?.returned_orders?.map(returnOrder => {
      const mainOrder = singleOrderGroup?.results?.find(order => order.order_id === returnOrder.order_id)
  
      return {
        ...returnOrder,
        quantity: returnOrder.adjust_type === "increase" ? `+${numberFormatter(returnOrder.quantity)}` : `-${numberFormatter(returnOrder.quantity)}`,
        ...(mainOrder?.meta_measurement?.name && {
          quantity: `${(returnOrder.adjust_type === "increase" ? `+${numberFormatter(Number(returnOrder.quantity) / Number(mainOrder.meta_measurement.base_quantity))}` : `-${numberFormatter(Number(returnOrder.quantity) / Number(mainOrder.meta_measurement.base_quantity))}`)} ${mainOrder.meta_measurement.name}`,
        })
      }
    })?.sort((a, b) => new Date(b.date) - new Date(a.date))
  
    const returnedOrdersLogs = allOrders?.filter(order => order.adjust_type !== "increase")
    const addedOrdersLogs = allOrders?.filter(order => order.adjust_type === "increase")
    const groupReturnedOrdersLogsByDate = Object?.groupBy?.(returnedOrdersLogs || [], (order) => new Date(order.modify_stamp || order.date).toGMTString())
    const groupAddedOrdersLogsByDate = Object?.groupBy?.(addedOrdersLogs || [], (order) => new Date(order.modify_stamp || order.date).toGMTString())
  
    return {
      addedOrdersLogs,
      returnedOrdersLogs,
      groupAddedOrdersLogsByDate,
      groupReturnedOrdersLogsByDate,
    }
  }
  
  const {
    returnedOrdersLogs,
    addedOrdersLogs,
    groupReturnedOrdersLogsByDate,
    groupAddedOrdersLogsByDate
  } = modifiedLogs()
  
  const refundLogs = () => {
    return singleOrderGroup?.results?.[0]?.bulk_details?.pay_log?.flatMap(pay_log => {
      if (!pay_log?.refund_log) return []
      return pay_log?.refund_log?.map(refund_log => ({
        ...refund_log,
        method: lodash.capitalize(pay_log.method)
      }))
    })
  }
  
  const isAllOrdersDisbursed = () => {
    return getAllDisbursedOrder()?.length === singleOrderGroup?.results?.length
  }
  
  const isOrderInvoiceAndNotConfirmed = () => {
    if (userProfile?.[0]?.account === "cb3589fc-ecf9-4f5c-bc0c-966a6be2b7a4") {
      return false
    }
  
    return (singleOrderGroup?.results?.[0]?.invoice && singleOrderGroup?.results?.[0]?.group_order?.status !== "payment_confirmed")
  }
  
  const profitLogsArray = () => {
    return singleOrderGroup?.results?.map(singleOrder => {
      return {
        product_name: singleOrder?.product?.name,
        product_id: singleOrder?.product?.id,
        logs: singleOrder?.profit_log?.map(log => {
          if (!singleOrder.meta_measurement || !singleOrder.meta_measurement?.base_quantity) return {
            ...log,
            quantity: `${log.quantity} ${singleOrder.product.unit_measurement}`,
            unit_cost: log.cost_price,
            cost_price: Number(log.quantity) * Number(log.cost_price),
            selling_price: Number(log.quantity) * Number(log.selling_price),
          }
    
          const quantity = Number(log.quantity) / Number(singleOrder.meta_measurement.base_quantity)
          const cost_price = Number(log.cost_price) * Number(log.quantity)
          return {
            ...log,
            cost_price,
            unit_cost: cost_price / quantity,
            quantity: `${quantity} ${singleOrder.meta_measurement.name}`,
            selling_price: Number(log.selling_price) * Number(log.quantity),
          }
        })
      }
    })
  }
  
  const isProfitLogs = () => {
    return profitLogsArray().reduce((prev, curr) => {
      return prev + (!!curr.logs ? 1 : 0)
    }, 0)
  }
  
  const handleModifyOrder = () => {
    const productsToAddToCard = singleOrderGroup?.results?.filter(order => !order.returned)?.map(order => ({
      ...order?.product,
      qty: order?.qty,
      unit: order?.qty,
      group_order_id: order?.group_order?.id,
      order_id: order?.id,
      pay_mode: order.pay_mode,
      name: order?.product?.name || order?.product_name,
      applied_tax: (order.tax || []),
      applied_discount: (order.discount || []).map(discount => ({...discount, value_type: discount.type}))[0],
      ...(order?.meta_measurement?.name && {
        selected_unit_measurement: order?.meta_measurement,
        selling_price: order?.meta_measurement?.selling_price
      }),
      ...(order.complimentary && {
        isComplimentary: order.complimentary,
        selling_price: 0
      }),
      sell_faulty: order?.sell_faulty || false,
      stock_unit: Number(order?.qty || 0) + Number(order?.product?.stock_unit),
      selling_price: order.unit_cost,
      isSellingPriceChanged: Number(order.unit_cost) !== Number(order.product.selling_price)
    }))
  
    addModifyOrderToLocalStorage(productsToAddToCard, singleOrderGroup?.results?.[0].group_order?.id)
    navigate(`/dashboard_sell/?pay_mode=${paymentMethodEnum[singleOrderGroup?.results?.[0]?.pay_mode].value}&type=modify_order`)
  }
  
  const [paymentRecord, setPaymentRecord] = useState({})
  const [viewModifiedLogs, setViewModifiedLogs] = useState([])
  const [viewSingleModifyOrderLog, setViewSingleModifyOrderLog] = useState('')
  
  return (
    <div>
      <PageHeader title={isOrderInvoiceAndNotPaid() ? "Quotation Detail" : "Sale History"}/>
      {showReceipt && <ReceiptModal data={singleOrderGroup?.results} onClose={setShowReceipt}/>}
      {showReturnedReceipt && <ReturnedOrderReceipt modified_logs={viewModifiedLogs} logId={viewSingleModifyOrderLog}
                                                    data={singleOrderGroup?.results}
                                                    onClose={setShowReturnedReceipt}/>}
    
      {showPaymentReceipt &&
        <PaymentRecordReceipt allPaymentRecord={splitOrPartialPaymentTransactions()} payment_record={paymentRecord}
                              data={singleOrderGroup?.results}
                              onClose={setShowPaymentReceipt}/>}
    
      {showPayModal && <PayBalanceModal onClose={setShowPayModal}
                                        isMutationLoading={payBalanceMutation.isLoading || payBalanceOfflineMutation.isLoading}
                                        handleSubmit={handlePayBalance}
                                        totalPayable={singleOrderGroup?.results?.[0]?.balance}/>}
    
      {showConfirmBankTransferModal &&
        <PayBalanceModal
          isTransferConfirmation
          onClose={setShowConfirmBankTransferModal}
          isMutationLoading={confirmGroupOrderMutation.isLoading}
          handleSubmit={handleConfirmedOrder}
        />}
    
    
      {showRefundModal && (
        <RefundBalanceModal
          isMutationLoading={refundMutation.isLoading}
          pay_log={singleOrderGroup?.results?.[0]?.bulk_details?.pay_log}
          onClose={setShowRefundModal}
          handleSubmit={refundMutation.mutate}
          totalPayable={Math.abs(singleOrderGroup?.results?.[0]?.balance)}/>
      )}
    
      {showDistributeProduct && <DisburseOrderModal orders={unDisbursedItems()} onClose={setShowDisburseOrder}
                                                    handleSubmit={handleDisburseOrder}
                                                    mutation={disburseOrderMutation}/>}
      {showInvoiceCheckoutModal &&
        <InvoiceCheckOutModal orders={singleOrderGroup?.results} onClose={setShowInvoiceCheckoutModal}/>}
    
    
      {(showReturnOrderCart && isSingleOrderGroupSuccess) && (
        <ReturnOrderModal
          onClose={setShowReturnOrderCart}
          groupOrder={singleOrderGroup?.results}/>
      )}
    
      {showOvaloopTransferModal && (
        <PaymentModal
          payment_details={singleOrderGroup?.results?.[0]?.ovaloop_transfer}
          order={singleOrderGroup?.results}
          onClose={setShowOvaloopTransferModal}
          dynamicAccountBody={{ group_order: singleOrderGroup?.results?.[0]?.group_order?.id }}
          verifyAccountQueryParam={{ group_order_id: singleOrderGroup?.results?.[0]?.group_order?.id }}
        />
      )}
      
      {status === "error" && (
        <ScreenLoading refetchFn={refetch} />
      )}

      <Fragment>
        <SingleOrderHeaderStyled>
          <FlexStyled className="navigation_section">
            <ArrowLeft className="arrow" color={theme.menuGray} size={18} onClick={navigate.bind(this, -1)}/>
            <FlexStyled>
              <Paragraph noTop color="menuGray">{groupOrderId}</Paragraph>
    
              <FlexStyled className="id_and_status">
                {isSingleOrderGroupSuccess && (
                  <Fragment>
                    <Heading color="black">Order:
                      <span style={{fontSize: 17}}> {groupOrderId.slice(0, 11)}... </span>
                      <Copy
                        color="black"
                        size={15}
                        variant="Bold"
                        id="copy_group_id"
                        onClick={() => handleCopyToClipBoard({
                          toast,
                          text: groupOrderId,
                          successMessage: "Group Order ID Copied"
                        })}
                      />
            
                      <Tooltip
                        anchorId="copy_group_id"
                        place="top" variant="dark"
                        content="Copy order group id"
                      />
                    </Heading>
                    {getOrderStatus(singleOrderGroup?.results?.[0]?.group_order?.status)}
  
                    {isAllOrdersDisbursed() && (
                      <Paragraph color="success" className="no_svg_border">
                        <IoMdCheckmarkCircleOutline/> Disbursed
                      </Paragraph>
                    )}
  
                    {/*{!isAllOrdersDisbursed() && !isGroupOrderStatusCancelledOrReturned() && (*/}
                    {/*  <Paragraph color="gray" className="no_svg_border">*/}
                    {/*    <RxPieChart/> Disbursed ({getAllDisbursedOrder()?.length}/{singleOrderGroup?.results?.length})*/}
                    {/*  </Paragraph>*/}
                    {/*)}*/}
                  </Fragment>
                )}
  
                {isSingleGroupOrderLoading && (
                  <Fragment>
                    <TableSkeleton count={1} noTop height={30} width={140} />
                    <TableSkeleton count={1} noTop height={30} width={140} />
                  </Fragment>
                )}
              </FlexStyled>
            </FlexStyled>
          </FlexStyled>

          <FlexStyled className="chips">
            {isSingleOrderGroupSuccess && (
              <Fragment>
                {!isOrderInvoiceAndNotConfirmed() && (
                  <Chips permissionCode={actionsPermissions.reprintReceipt} title="Print" Icon={TiPrinter} active
                         onClick={setShowReceipt}/>
                )}
  
                {!isOffline && (
                  <Fragment>
                    <Chips title="Download" Icon={AiOutlineFilePdf} active onClick={handleDownloadReceipt}/>
  
                    {isOrderInvoiceAndNotPaid() && (
                      <Chips title="Proceed to payment" active onClick={setShowInvoiceCheckoutModal}
                             permissionCode={singleOrderGroup?.results?.[0]?.invoice ? actionsPermissions.acceptInvoicePayment : actionsPermissions.confirmPaymentForOrder}/>
                    )}
  
                    {!isAllOrdersDisbursed() && !isGroupOrderStatusCancelledOrReturned() && (
                      <Chips title="Disburse" active onClick={setShowDisburseOrder}
                             permissionCode={actionsPermissions.disburseItemFromStore}/>
                    )}
  
                    {isPayModeTransferAndNotConfirmed() && (
                      <Chips title="Confirm Order" active onClick={setShowConfirmBankTransferModal}
                             permissionCode={actionsPermissions.confirmPaymentForOrder}/>
                    )}
  
                    {isOvaloopTransferAndNotConfirmed() && (
                      <Chips title="Confirm ovaloop transfer" active onClick={setShowOvaloopTransferModal}/>
                    )}
                  </Fragment>
                )}
  
                {isPayModeCredit() && (
                  <Chips
                    active
                    title="Pay Balance"
                    onClick={setShowPayModal}
                    permissionCode={singleOrderGroup?.results?.[0]?.invoice ? actionsPermissions.acceptInvoicePayment : actionsPermissions.setSplitPayment}
                  />
                )}
  
                {isRefundAvailable() && (
                  <Chips title="Refund" active onClick={setShowRefundModal}
                         permissionCode={actionsPermissions.refundPayment}/>
                )}
  
                {!isOffline && (
                  <Fragment>
                    {!isGroupOrderStatusCancelledOrReturned() && (
                      <Chips title="Cancel" permissionCode={actionsPermissions.cancelOrder} active
                             onClick={handleCancelOrder}/>
                    )}
      
                    {!isGroupOrderStatusCancelledOrReturned() && (
                      <Chips
                        active
                        title="Modify Order"
                        onClick={handleModifyOrder}
                        permissionCode={singleOrderGroup?.results?.[0]?.invoice ? actionsPermissions.modifyInvoice : actionsPermissions.markOrderAsReturned}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
  
            {isSingleGroupOrderLoading && (
              <Fragment>
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
                <TableSkeleton count={1} noTop height={35} width={90} />
              </Fragment>
            )}

            {/*<Chips title="Mark Item For Delivery" active />*/}
          </FlexStyled>
        </SingleOrderHeaderStyled>

        <SingleOrderLayoutStyled>

          <div className="main">
            {isSingleOrderGroupSuccess && (
              <SpaceBetweenStyled className="order_staff_bank">
                <OrderDetailsCard title="Order" data={orderDetails} />
                <OrderDetailsCard title="Staff" data={staffDetails} />
                <OrderDetailsCard title="Bank" data={bankDetails} />
              </SpaceBetweenStyled>
            )}
  
            {isSingleGroupOrderLoading && (
              <SingleOrderLayoutStyled>
                <FlexStyled className="subscription_card_details">
                  <TableSkeleton count={1} height={180} width="210px" noTop />
                  <TableSkeleton count={1} height={180} width="210px" noTop />
                  <TableSkeleton count={1} height={140} width="210px" noTop />
                </FlexStyled>
              </SingleOrderLayoutStyled>
            )}
  
            {isSingleOrderGroupSuccess && (
              (splitOrPartialPaymentTransactions().length || Number(singleOrderGroup?.results?.[0]?.balance)) > 0 && (
                <OrdersTableStyled>
      
                  <SpaceBetweenStyled>
                    <Heading size={1.4} color="black">Payment Records</Heading>
                    {(Number(singleOrderGroup?.results?.[0]?.balance) !== 0 && !isGroupOrderStatusCancelledOrReturned()) && (
                      <FlexStyled>
                        <Heading size={1.2} color="black">
                          {Number(singleOrderGroup?.results?.[0]?.balance) > 0 ? "Balance:" : "Refund:"}
                        </Heading>
                        <Heading size={1.2}
                                 color="error">{currencyFormatter(Math.abs(singleOrderGroup?.results?.[0]?.balance))}</Heading>
                        <span> </span>
                        <span></span>
                      </FlexStyled>
                    )}
                  </SpaceBetweenStyled>
  
                  <Table lastHeaderPosition="flex-end" headers={splitPaymentRecordTable.headers}
                         noCols={splitPaymentRecordTable.headers.length}
                         equalWidth>
                    {splitOrPartialPaymentTransactions().map((item, k) => (
                      <Fragment key={k}>
                        <TableItemWithEyeAction returnFullData onClick={(record) => {
                          setPaymentRecord(record)
                          setShowPaymentReceipt()
                        }}
                                                actionPosition="flex-end"
                                                count={k}
                                                pageCount={1}
                                                data={item}
                                                keys={splitPaymentRecordTable.values} statusPosition={30}/>
                        <Line/>
                      </Fragment>
                    ))}
        
                    {splitOrPartialPaymentTransactions().length === 0 && (
                      <NoQueryData text="No payment record"/>
                    )}
                  </Table>
                </OrdersTableStyled>
              )
            )}
  
            {isSingleGroupOrderLoading && (
              <TableSkeleton count={2} height={70}/>
            )}
  
            {/* REFUND LOGS */}
            {isSingleOrderGroupSuccess && (
              refundLogs()?.length > 0 && (
                <OrdersTableStyled>
                  <Heading size={1.4} color="black">Refund Logs</Heading>
        
                  <Table
                    equalWidth
                    headers={refundLogTable.headers}
                    noCols={refundLogTable.headers.length}
                  >
                    {refundLogs()?.map((item, k) => (
                      <Fragment key={k}>
                        <TableItemWithStatus
                          count={k}
                          pageCount={1}
                          data={item}
                          keys={refundLogTable.values}
                          noAction
                          statusPosition={30}
                        />
                        <Line/>
                      </Fragment>
                    ))}
                  </Table>
                </OrdersTableStyled>
              )
            )}
  
            {isSingleGroupOrderLoading && (
              <TableSkeleton count={2} height={70}/>
            )}
  
  
            {isSingleOrderGroupSuccess && (
              <OrdersTableStyled>
                <Heading size={1.4} color="black">Orders</Heading>
                {singleOrderGroup?.results?.map((item, k) => (
                  <Fragment key={k}>
                    <TableItemOrderAndWIP order={item}/>
                    <Line/>
                  </Fragment>
                ))}
              </OrdersTableStyled>
            )}
  
  
            {/* RETURNED ORDER LOGS */}
            {isSingleGroupOrderLoading && (
              <TableSkeleton count={1} height={200}/>
            )}
  
            {isSingleOrderGroupSuccess && (
              returnedOrdersLogs?.length > 0 && (
                <OrdersTableStyled>
                  <SpaceBetweenStyled>
                    <Heading size={1.4} color="black">Returned Order Logs</Heading>
          
                    <Chips
                      active
                      Icon={TiPrinter}
                      title={groupReturnedOrdersLogsByDate ? "Print All" : 'Print Returned Orders'}
                      onClick={() => {
                        setViewModifiedLogs(returnedOrdersLogs)
                        setViewSingleModifyOrderLog('')
                        setShowReturnedReceipt()
                      }}
                      permissionCode={actionsPermissions.reprintReceipt}
                    />
                  </SpaceBetweenStyled>
                  
                  {groupReturnedOrdersLogsByDate && (
                    <Fragment>
                      {Object.entries(groupReturnedOrdersLogsByDate).map(([key, value]) => (
                        <ContainerStyled key={key}>
                          <br/> <br/>
                          <SpaceBetweenStyled>
                            <Heading size={1.1} color="black">{key}</Heading>
                            
                            <Chips
                              active
                              Icon={TiPrinter}
                              title="Print"
                              onClick={() => {
                                setViewModifiedLogs(value)
                                setViewSingleModifyOrderLog('')
                                setShowReturnedReceipt()
                              }}
                              permissionCode={actionsPermissions.reprintReceipt}
                            />
                          </SpaceBetweenStyled>
                          <Table
                            equalWidth
                            headers={returnedOrdersLogTable.headers}
                            noCols={returnedOrdersLogTable.headers.length}
                          >
                            {value?.map((item, k) => (
                              <Fragment key={k}>
                                <TableItemWithStatus
                                  count={k}
                                  pageCount={1}
                                  data={item}
                                  keys={returnedOrdersLogTable.values}
                                  onView={(singleOrder) => {
                                    setViewSingleModifyOrderLog(singleOrder.order_id)
                                    setShowReturnedReceipt()
                                  }}
                                  statusPosition={30}
                                />
                                <Line/>
                              </Fragment>
                            ))}
                          </Table>
                        </ContainerStyled>
                      ))}
                    </Fragment>
                  )}
                  
                  {!groupReturnedOrdersLogsByDate && (
                    <Table
                      equalWidth
                      headers={returnedOrdersLogTable.headers}
                      noCols={returnedOrdersLogTable.headers.length}
                    >
                      {returnedOrdersLogs?.map((item, k) => (
                        <Fragment key={k}>
                          <TableItemWithStatus
                            count={k}
                            pageCount={1}
                            data={item}
                            keys={returnedOrdersLogTable.values}
                            onView={(singleOrder) => {
                              setViewSingleModifyOrderLog(singleOrder.order_id)
                              setShowReturnedReceipt()
                            }}
                            statusPosition={30}
                          />
                          <Line/>
                        </Fragment>
                      ))}
                    </Table>
                  )}
                </OrdersTableStyled>
              )
            )}
  
  
            {/* ADDED ORDER LOGS */}
            {isSingleGroupOrderLoading && (
              <TableSkeleton count={1} height={200}/>
            )}
  
            {isSingleOrderGroupSuccess && (
              addedOrdersLogs?.length > 0 && (
                <OrdersTableStyled>
                  <SpaceBetweenStyled>
                    <Heading size={1.4} color="black">Added Order Logs</Heading>
          
                    <Chips
                      active
                      Icon={TiPrinter}
                      title={groupAddedOrdersLogsByDate ? "Print All" : "Print Added Orders"}
                      onClick={() => {
                        setViewModifiedLogs(addedOrdersLogs)
                        setViewSingleModifyOrderLog('')
                        setShowReturnedReceipt()
                      }}
                      permissionCode={actionsPermissions.reprintReceipt}
                    />
                  </SpaceBetweenStyled>
                  
                  {groupAddedOrdersLogsByDate && (
                    <Fragment>
                      {Object.entries(groupAddedOrdersLogsByDate).map(([key, value]) => (
                        <ContainerStyled key={key}>
                          <br/> <br/>
                          <SpaceBetweenStyled>
                            <Heading size={1.1} color="black">{key}</Heading>
                            
                            <Chips
                              active
                              Icon={TiPrinter}
                              title="Print"
                              onClick={() => {
                                setViewModifiedLogs(value)
                                setViewSingleModifyOrderLog('')
                                setShowReturnedReceipt()
                              }}
                              permissionCode={actionsPermissions.reprintReceipt}
                            />
                          </SpaceBetweenStyled>
                          <Table
                            equalWidth
                            headers={returnedOrdersLogTable.headers}
                            noCols={returnedOrdersLogTable.headers.length}
                          >
                            {value?.map((item, k) => (
                              <Fragment key={k}>
                                <TableItemWithStatus
                                  count={k}
                                  pageCount={1}
                                  data={item}
                                  keys={returnedOrdersLogTable.values}
                                  onView={(singleOrder) => {
                                    setViewSingleModifyOrderLog(singleOrder.order_id)
                                    setShowReturnedReceipt()
                                  }}
                                  statusPosition={30}
                                />
                                <Line/>
                              </Fragment>
                            ))}
                          </Table>
                        </ContainerStyled>
                      ))}
                    </Fragment>
                  )}
                  
                  {!groupReturnedOrdersLogsByDate && (
                    <Table
                      equalWidth
                      headers={returnedOrdersLogTable.headers}
                      noCols={returnedOrdersLogTable.headers.length}
                    >
                      {addedOrdersLogs?.map((item, k) => (
                        <Fragment key={k}>
                          <TableItemWithStatus
                            count={k}
                            pageCount={1}
                            data={item}
                            keys={returnedOrdersLogTable.values}
                            onView={(singleOrder) => {
                              setViewSingleModifyOrderLog(singleOrder.order_id)
                              setShowReturnedReceipt()
                            }}
                            statusPosition={30}
                          />
                          <Line/>
                        </Fragment>
                      ))}
                    </Table>
                  )}
                </OrdersTableStyled>
              )
            )}
  
  
            {isSingleGroupOrderLoading && (
              <TableSkeleton count={2} height={70}/>
            )}
  
            {isSingleOrderGroupSuccess && (
              <OrdersTableStyled>
                <Heading size={1.4} color="black">Disbursed</Heading>
                <Table headers={disbursedOrderTable.headers} equalWidth noCols={disbursedOrderTable.headers.length}>
                  {getAllDisbursedOrder()?.length > 0 && (
                    [...getAllDisbursedOrder()]?.map((items, k) =>
                      <TableItemWithStatus
                        count={k}
                        pageCount={1}
                        keys={disbursedOrderTable.values}
                        statusPosition={9}
                        key={k}
                        data={items}
                        noAction
                      />
                    ))}
      
                  {getAllDisbursedOrder()?.length < 1 && (
                    <NoQueryData text="No Disbursed Order" />
                  )}
                </Table>
              </OrdersTableStyled>
            )}
  
            {(isSingleOrderGroupSuccess && actionPermissionFn.check(routesPermissions.viewCostPrices) && !!isProfitLogs()) && (
              <OrdersTableStyled>
                <Heading size={1.4}  color="black">Profit Logs</Heading>
  
                {profitLogsArray().map(order =>
                  order?.logs?.length > 0 && (
                    <ContainerStyled key={order?.product_id}>
                      <br/>
                      <Link to={`/dashboard/inventory/product/${order?.product_id}`}>
                        <Heading size={1} bold color="black">Order: {order?.product_name}</Heading>
                      </Link>
                      <Table headers={orderProfitLogTable.headers} equalWidth
                             noCols={orderProfitLogTable.headers.length}>
                        {
                          order?.logs?.map((items, k) =>
                            <TableItemWithStatus
                              count={k}
                              pageCount={1}
                              keys={orderProfitLogTable.values}
                              statusPosition={9}
                              key={k}
                              data={items}
                              noAction
                            />
                        )}
                      </Table>
                      
                    </ContainerStyled>
                  )
                )}
                
              </OrdersTableStyled>
            )}
          </div>

          <div className="side">
            {isSingleOrderGroupSuccess && (
              <CustomerDataStyled>
                <Heading size={1.4}  color="black">Customer</Heading>
    
                {!!customerDetails.fullName && (
                  <Fragment>
                    <div className="details">
                      <FlexStyled $gap={.6}>
                        <Heading color="black">Full Name:</Heading>
                        <Paragraph color="black" noTop>
                          {!!customerDetails?.isStoreFront && (
                            <Link to="#">{customerDetails.fullName}</Link>
                          )}

                          {(!!customerDetails.firstName && !customerDetails?.isStoreFront) ?
                            <Link to={`/dashboard/inventory/customer/${customerDetails.id}`}>{customerDetails.fullName}</Link> :
                            "No Name"
                          }
                        </Paragraph>
                      </FlexStyled>
                      <Line />
                    </div>
        
                    <div className="details">
                      <FlexStyled $gap={.6}>
                        <Heading color="black">Email Address:</Heading>
                        <Paragraph color="black" noTop>{customerDetails?.email || "No Email"}</Paragraph>
                      </FlexStyled>
                      <Line />
                    </div>
        
                    <div className="details">
                      <FlexStyled $gap={.6}>
                        <Heading color="black">Phone Number:</Heading>
                        <Paragraph color="black" noTop>{customerDetails?.phone || "No Phone number"}</Paragraph>
                      </FlexStyled>
                      <Line />
                    </div>
        
                    <div className="details">
                      <FlexStyled $gap={.6}>
                        <Heading color="black">Address:</Heading>
                        <Paragraph color="black" noTop>
                          {customerDetails?.address || "No Address"}
                        </Paragraph>
                      </FlexStyled>
                      <Line />
                    </div>
                  </Fragment>
                )}
              </CustomerDataStyled>
            )}
  
            {isSingleGroupOrderLoading && (
              <CustomerDataStyled>
                <TableSkeleton count={5} height={35} />
              </CustomerDataStyled>
            )}
  
            {isSingleGroupOrderLoading && (
              <CustomerDataStyled>
                <TableSkeleton count={5} height={35} />
              </CustomerDataStyled>
            )}
            
            
            {isSingleOrderGroupSuccess && (
              <CustomerDataStyled>
                <Heading size={1.4} color="black">Summary</Heading>
    
                <SpaceBetweenStyled>
                  <Heading color="secondary">Total Item cost:</Heading>
                  <Heading color="secondary">{summary?.totalItemCost}</Heading>
                </SpaceBetweenStyled>
    
                <SpaceBetweenStyled>
                  <Heading color="secondary">Services:</Heading>
                  <Heading color="secondary">+ {summary?.totalServiceCost}</Heading>
                </SpaceBetweenStyled>
    
                <SpaceBetweenStyled>
                  <Heading color="secondary">Discount:</Heading>
                  <Heading color="secondary">- {summary?.totalDiscountAmount}</Heading>
                </SpaceBetweenStyled>
    
                <SpaceBetweenStyled>
                  <Heading color="secondary">Tax:</Heading>
                  <Heading color="secondary">+ {summary?.totalTaxCost}</Heading>
                </SpaceBetweenStyled>
    
                {!!singleOrderGroup?.results?.[0]?.group_order?.group_delivery && (
                  <SpaceBetweenStyled>
                    <Heading color="secondary">Delivery Rate:</Heading>
                    <Heading color="secondary">+ {summary?.totalDeliveryRate}</Heading>
                  </SpaceBetweenStyled>
                )}
    
                <Line />
    
                <SpaceBetweenStyled className="total">
                  <Heading color="black" bold size={1.1}>Total:</Heading>
                  <Heading color="black" bold size={1.1}>{summary?.overallTotal}</Heading>
                </SpaceBetweenStyled>
    
                {!!singleOrderGroup?.results?.[0]?.balance && (
                  <SpaceBetweenStyled>
                    <Heading color="black" bold size={1.1}>Balance:</Heading>
                    <Heading color="black" bold size={1.1}>{currencyFormatter(singleOrderGroup?.results?.[0]?.balance)}</Heading>
                  </SpaceBetweenStyled>
                )}
    
                <Line />
    
                {singleOrderGroup?.results?.[0]?.ex_currency && (
                  <SpaceBetweenStyled >
                    <Heading color="secondary" size={1.1}>Amount in {singleOrderGroup?.results?.[0]?.ex_currency}:</Heading>
                    <Heading color="secondary" size={1.1}>{currencyFormatterFn("en-NG", singleOrderGroup?.results?.[0]?.ex_currency)(singleOrderGroup?.results?.[0]?.group_order?.exchange_value)}</Heading>
                  </SpaceBetweenStyled>
                )}
    
                {singleOrderGroup?.results?.[0]?.ex_currency && (
                  <SpaceBetweenStyled>
                    <Heading color="secondary" size={1.1}>Exchange Rate:</Heading>
                    <Heading color="secondary" size={1.1}>{currencyFormatterFn("en-NG", singleOrderGroup?.results?.[0]?.ex_currency)(singleOrderGroup?.results?.[0]?.exchange_rate)}</Heading>
                  </SpaceBetweenStyled>
                )}
    
                <div>
                  {(singleOrderGroup?.results?.[0]?.pay_mode?.toUpperCase() === "SUBSCRIPTION") && (
                    <Fragment>
                      <br/>
                      <SpaceBetweenStyled>
                        <Heading color="secondary">Subscription Name: </Heading>
                        <Heading color="secondary">{singleOrderGroup?.results?.[0]?.subscription?.name}</Heading>
                      </SpaceBetweenStyled>
                    </Fragment>
                  )}
                </div>
              </CustomerDataStyled>
            )}
          </div>
        </SingleOrderLayoutStyled>
      </Fragment>
    </div>
  )
}

export default SingleOrder


const ReturnOrderModal = memo(({ onClose, groupOrder }) => {
  const { currencyFormatter } = useDataTypeFormatter()
  
  const availableProductsForReturn = groupOrder.filter(order => !(order.returned === true && order.return_type === "order"))

  const products = availableProductsForReturn.map(order => {
    const services = order?.service.map(service => ({
      ...service,
      name: service.service_name,
      cost: Number(service.service_amount),
      fullName: `${service.service_name} - ${currencyFormatter(service.service_amount)}`,
      id: service.service_id
    }))
    
    return {
      ...order?.product,
      unit: Number(order?.qty),
      stock_unit: Number(order?.qty),
      service: services
    }
  })
  
  const [sellState, reducer] = useReducer(sellReducer, { ...sellReducerInitialValues, cart: products })
  
  return (
    <SellProvider.Provider value={{ ...sellState, reducer }}>
      <ModalLayoutStyled onClick={onClose}>
        <ReturnOrderCart onClose={onClose} groupOrderDetails={availableProductsForReturn[0]} mainOrders={availableProductsForReturn} />
      </ModalLayoutStyled>
    </SellProvider.Provider>
  )
})