const colors = [
	"#C3F7DE",
	"#B8ACFF",
	"#FEC6C6",
	"#FEDEBC",
	"#DEEFFF",
	"#e28743",
	"#21130d",
	"#273043",
	"#000000",
	"#631D76",
	"#B8BDB5",
	"#916953",
	"#F96900",
	"#65DEF1",
	"#DC493A",
	"#4392F1",
	// "#E4FF1A",
	"#6EEB83",
	"#685155",
	"#6C0E23",
	"#109648",
	"#957D95",
	"#2F4B26",
]

const getName = (name) => {
	if(name.length > 20) {
		return name.slice(0, 25)
	}

	return name
}

const shuffleColors = () => {
	return colors.sort(function () {
		return Math.random() - 0.5;
	});
}

// use useMemo to cache this expensive function
export default (data, valueKey, nameKey = "name") => {
	const generatedColors = shuffleColors()
	
	if (valueKey === "payment_summary") {
		return Object.keys(data).map((item, idx) => {
			return {name: item, value: data[item], color: generatedColors[idx]}
		})
	}
	
	if(valueKey === "sales") {
		return data.map((item, idx) => {
			return { name: getName(item[nameKey]), value: item[valueKey]?.amount, color: generatedColors[idx] }
		}).slice(0, 10)
	}
	
	return data.map((item, idx) => {
		return { name: getName(item[nameKey]), value: item[valueKey], color: generatedColors[idx] }
	}).slice(0, 10)
}


export const dashboardSalesChartFormatter = (data, dateFormatter) => {
	return data.map(item => {
		return { name: dateFormatter(item.day), value: item.amount }
	}).slice(0, 10)
}