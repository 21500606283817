import useGlobalContext from "../../useContexts/useGlobalContext";
import {
	bulkPartialPayForCustomerService,
	cancelSingleGroupOrderByGroupIdService,
	confirmGroupOrderByIdService,
	createOrderService,
	disburseOrderService,
	getGroupOrderByBusinessIdAndTableTrackIdService,
	getGroupOrderByBusinessIdService,
	getGroupOrderByStaffIdService,
	getGroupOrderWithDueDateByBusinessIdService,
	getKitchenGroupByBusinessIdAndGroupId,
	getSingleGroupByBusinessIdAndGroupId,
	partialPayForGroupOrderService,
	payForGroupOrderService,
	payInvoiceOrderService,
	refundForGroupOrderService,
	returnOrderService,
	returnSingleGroupOrderByGroupIdService,
	updateKitchenGroupOrderService
} from "../../../services/orderServices";
import queryKeys from "../queryKeys";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getDeliveryService} from "../../../services/deliveryServices";
import {useParams, useSearchParams} from "react-router-dom";
import {scrollToTop} from "../../../utils";
import useTablePrefetch from "../../usePrefetchQuery/useTablePrefetch";
import orderQueryKey from "./orderQueryKey";
import productQueryKey from "../useProductServices/productQueryKey";
import customerQueryKey from "../useCustomerServices/customQueryKey";
import staffQueryKey from "../useStaffServices/staffQueryKey";
import inventoryQueryKey from "../useInventoryServices/inventoryQueryKey";
import errorHandler from "../../../utils/errorHandler";
import debtorQueryKey from "../useDebtorServices/debtorQueryKey";
import {getAllObjectKeysWithValues} from "../../../utils/handleQueryParams";
import useGroupOrderIdParams from "../../useUtils/useGroupOrderIdParams";
import {
	desktopOfflineQueryKeys,
	useDesktopGroupOrdersServiceFromLocalDB,
	useDesktopOnlyOfflineGroupOrdersServiceFromLocalDB,
	useDesktopSingleOrdersServiceFromLocalDB
} from "../../useDesktop/useDesktopServicesFromLocalDB";
import useErrorLogger from "../../useUtils/useErrorLogger";
import {errorTypes} from "../../../utils/logger";
import {IPC_EVENTS} from "../../../shared/ipcEvents";
import {createEntryInDB} from "../../useDesktop/useDesktopOfflineMutation";


const useGroupOrderByBusinessIdQuery = (props = {}) => {
	const {
		searchValue = "",
		status = "",
		fromDateValue = "",
		toDateValue = "",
		customerValue = "",
		invoice = false
	} = props || {}
	
	scrollToTop()
	const {getBusinessId, isOffline} = useGlobalContext()
	const businessId = getBusinessId()
	
	const offlineData = useDesktopGroupOrdersServiceFromLocalDB({
		status,
		search: searchValue,
		customer: customerValue,
		...(!!invoice && {invoice: true}),
		...((fromDateValue && toDateValue) && {fromDate: fromDateValue, toDate: toDateValue})
	})
	
	const onlyOfflineOrders = useDesktopOnlyOfflineGroupOrdersServiceFromLocalDB({
		status,
		search: searchValue,
		customer: customerValue,
		...(!!invoice && { invoice: true }),
		...((fromDateValue && toDateValue) && { fromDate: fromDateValue, toDate: toDateValue })
	})
	
	
	
	const onlineData = useQuery({
		enabled: !!businessId,
		queryKey: orderQueryKey.main(props),
		queryFn: () => getGroupOrderByBusinessIdService(businessId, searchValue, status, fromDateValue, toDateValue, customerValue, invoice)
	})
	
	
	const fetch = () => {
		return isOffline ? offlineData : { ...(onlineData || {}), data: { ...(onlineData?.data || {}), results: [...(onlyOfflineOrders?.data || []), ...(onlineData?.data?.results || [])] } }
	}
	
	return fetch()
}


export const useGroupOrderWithDueDateByBusinessIdQuery = (props) => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()
	
	return useQuery({
		queryKey: orderQueryKey.withDueDate(getAllObjectKeysWithValues(props)),
		queryFn: () => getGroupOrderWithDueDateByBusinessIdService({ businessId, ...props })
	})
}


export const useKitchenGroupOrderByBusinessIdQuery = () => {
	const { getBusinessId } = useGlobalContext()
	const businessId = getBusinessId()
	
	return useQuery({
		queryKey: orderQueryKey.kitchenDisplay(""),
		queryFn: () => getKitchenGroupByBusinessIdAndGroupId(businessId)
	})
}

const useGroupOrderByStaffIdQuery = ({ staffIdByProps, ...others }) => {
	const { staffId } = useParams()
	const { getBusinessId } = useGlobalContext()
	const business_id = getBusinessId()
	
	const staff_id = staffId || staffIdByProps
	
	return useQuery({
		queryKey: orderQueryKey.byStaffId(staff_id, others),
		queryFn: () => getGroupOrderByStaffIdService({ business_id, staff_id, ...others })
	})
}

const useSingleGroupByBusinessIdAndGroupIdQuery = (groupId, queryParams = {}) => {
	const [searchParam] = useSearchParams()
	const groupOrderId = useGroupOrderIdParams()
	
	const { getBusinessId, isOffline } = useGlobalContext()
	const businessId = getBusinessId()
	
	const offlineData = useDesktopSingleOrdersServiceFromLocalDB(groupOrderId || groupId)
	
	const query = { business_id: businessId, group_order_id: groupOrderId || groupId, ...queryParams }
	
	query.invoice = !!searchParam.get("invoice");
	
	const onlineData = useQuery({
		enabled: !isOffline,
		queryKey: orderQueryKey.byGroupOrderId(groupOrderId || groupId, queryParams),
		queryFn: () => getSingleGroupByBusinessIdAndGroupId(query)
	})
	
	const fetch = () => {
		if(!isOffline && !!searchParam.get("is_offline")) {
			return offlineData
		}
		
		return isOffline ? offlineData : onlineData
	}
	
	return fetch()
}

const useGroupOrdersByBusinessIdAndTableTrackIdQuery = (tableTrackId) => {
	scrollToTop()
	const {getBusinessId, isOffline} = useGlobalContext()
	const businessId = getBusinessId()
	
	const offlineData = useDesktopGroupOrdersServiceFromLocalDB({tableTrackId, is_track_id: true})
	
	const onlineData = useQuery({
		queryKey: orderQueryKey.byTableTrackId(tableTrackId),
		queryFn: () => getGroupOrderByBusinessIdAndTableTrackIdService(businessId, tableTrackId)
	})
	
	const fetch = () => {
		return isOffline ? offlineData : onlineData
	}
	
	return fetch()
}

const useCreateOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const tablePrefetch = useTablePrefetch()
	const {toast, getBusinessId} = useGlobalContext()
	
	const businessId = getBusinessId()
	
	return useMutation({
		mutationFn: data => {
			return createOrderService(data)
		},
		
		onSuccess: (res) => {
			successFn(res)
			toast.success("Order Created", "created")
			const isSetForDelivery = res[0].set_for_delivery
			
			queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: orderQueryKey.analytics })
			queryClient.refetchQueries({ queryKey: orderQueryKey.mainListKey })
			queryClient.refetchQueries({ queryKey: productQueryKey.all })
			queryClient.refetchQueries({ queryKey: customerQueryKey.all })
			queryClient.refetchQueries({ queryKey: staffQueryKey.all})
			queryClient.refetchQueries({ queryKey: debtorQueryKey.all})
			queryClient.refetchQueries({ queryKey: queryKeys.DASHBOARD_ANALYTICS })
			
			// queryClient.prefetchQuery({
			// 	queryKey: orderQueryKey.mainList(),
			// 	queryFn: () => getGroupOrderByBusinessIdService(businessId)
			// })
			
			if (!!isSetForDelivery) {
				queryClient.prefetchQuery(`${queryKeys.DELIVERIES}_${businessId}`, getDeliveryService.bind(this, businessId))
			}
			
			tablePrefetch.fetch()
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				errorType: errorTypes.createSales,
				error: formatError, payload: variables
			})
		}
	})
}

const usePayForGroupOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const {toast, isOffline} = useGlobalContext()
	const tablePrefetch = useTablePrefetch()
	
	return useMutation({
		mutationFn: (body) => {
			return isOffline ? createEntryInDB(IPC_EVENTS.PAY_FOR_ALL_ORDERS_IN_TABLE, body) : payForGroupOrderService(body)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order Payed", "payed")
			queryClient.invalidateQueries({queryKey: orderQueryKey.all}).catch()
			queryClient.refetchQueries({queryKey: debtorQueryKey.all}).catch()
			queryClient.refetchQueries({queryKey: queryKeys.TABLES}).catch()
			
			tablePrefetch.fetch()
			
			if (isOffline) {
				queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all}).catch()
				queryClient.prefetchQuery({queryKey: desktopOfflineQueryKeys.all}).catch()
			}
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.payForGroupOrder,
			})
		}
	})
}

const usePartialPayForGroupOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return partialPayForGroupOrderService(body.groupOrderId, body)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order Payed", "payed")
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: debtorQueryKey.all})
			queryClient.refetchQueries({ queryKey: queryKeys.DASHBOARD_ANALYTICS })
			
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.partialPayForGroupOrder,
			})
		}
	})
}


export const useBulkPartialPayForCustomersMutation = ({successFn}) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const {toast} = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return bulkPartialPayForCustomerService(body)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order Payed", "payed")
			queryClient.refetchQueries({queryKey: customerQueryKey.all}).catch()
			queryClient.refetchQueries({queryKey: orderQueryKey.all}).catch()
			queryClient.refetchQueries({queryKey: debtorQueryKey.all}).catch()
			queryClient.refetchQueries({queryKey: queryKeys.DASHBOARD_ANALYTICS}).catch()
			
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.partialPayForGroupOrder,
			})
		}
	})
}


export const useRefundForGroupOrderMutation = ({successFn}) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const {toast} = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return refundForGroupOrderService(body)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Refund successful", "refund")
			queryClient.refetchQueries({queryKey: orderQueryKey.all})
			queryClient.refetchQueries({queryKey: debtorQueryKey.all})
			queryClient.refetchQueries({queryKey: queryKeys.DASHBOARD_ANALYTICS})
			
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.refundForGroupOrder,
			})
		}
	})
}

const useConfirmGroupOrderByIdMutation = ({successFn}) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const {toast} = useGlobalContext()
	
	return useMutation({
		mutationFn: ({id, ...values}) => {
			return confirmGroupOrderByIdService(id, values)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order Confirmed", "confirmed")
			queryClient.refetchQueries({queryKey: orderQueryKey.all})
			queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
			queryClient.refetchQueries({queryKey: debtorQueryKey.all})
			queryClient.refetchQueries({queryKey: queryKeys.DASHBOARD_ANALYTICS})
			
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.confirmGroupOrder,
			})
		}
	})
}


const useCancelSingleGroupOrderByGroupIdMutation = ({ successFn, tableTrackId = "" }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const {toast, getBusinessId, isOffline} = useGlobalContext()
	const tablePrefetch = useTablePrefetch()
	
	return useMutation({
		mutationFn: (groupId) => {
			return isOffline ? createEntryInDB(IPC_EVENTS.CANCEL_GROUP_ORDER, {groupId}) : cancelSingleGroupOrderByGroupIdService(getBusinessId(), groupId)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order Cancelled", "cancelled")
			
			if (isOffline) {
				queryClient.refetchQueries({queryKey: desktopOfflineQueryKeys.all}).catch()
				queryClient.invalidateQueries({queryKey: desktopOfflineQueryKeys.all}).catch()
			}
			
			queryClient.refetchQueries({queryKey: orderQueryKey.all})
			queryClient.refetchQueries({queryKey: inventoryQueryKey.all})
			queryClient.refetchQueries({queryKey: debtorQueryKey.all})
			queryClient.refetchQueries({queryKey: queryKeys.DASHBOARD_ANALYTICS})
			queryClient.refetchQueries({queryKey: productQueryKey.all})
			
			tablePrefetch.fetch(tableTrackId)
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.cancelGroupOrder,
			})
		}
	})
}

const useReturnSingleGroupOrderByGroupIdMutation = ({ successFn, tableTrackId = "" }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	const tablePrefetch = useTablePrefetch()
	
	return useMutation({
		mutationFn: (groupId) => {
			return returnSingleGroupOrderByGroupIdService(getBusinessId(), groupId)
		},
		
		onSuccess: () => {
			toast.success("Order Returned", "returned")
			
			successFn()
			queryClient.refetchQueries({ queryKey: productQueryKey.all })
			queryClient.refetchQueries({ queryKey: productQueryKey.mainProductsList })
			queryClient.refetchQueries({ queryKey: queryKeys.DASHBOARD_ANALYTICS })
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
			queryClient.refetchQueries({ queryKey: debtorQueryKey.all })
			
			
			tablePrefetch.fetch(tableTrackId)
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.returnGroupOrder,
			})
		}
	})
}

export const useModifyOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return returnOrderService(body)
		},
		
		onSuccess: () => {
			toast.success("Order Returned", "returned")
			
			successFn()
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
			queryClient.refetchQueries({ queryKey: debtorQueryKey.all })
			queryClient.refetchQueries({ queryKey: queryKeys.DASHBOARD_ANALYTICS })
			queryClient.refetchQueries({ queryKey: productQueryKey.all })
			
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.modifyGroupOrder,
			})
		}
	})
}

export const usePayInvoiceOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const { toast } = useGlobalContext()
	
	return useMutation({
		mutationFn: ({ id, body }) => {
			return payInvoiceOrderService(id, body)
		},
		
		onSuccess: () => {
			toast.success("Invoice paid", "paid")
			
			successFn()
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.refetchQueries({ queryKey: inventoryQueryKey.all })
			queryClient.refetchQueries({ queryKey: debtorQueryKey.all })
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.payInvoiceOrder,
			})
		}
	})
}

const useDisburseOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const {groupOrderId} = useParams()
	const queryClient = useQueryClient()
	const {toast} = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return disburseOrderService(body.order_id, {...body, disburse_status: "disbursed"})
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Orders disbursed", "disbursed")
			queryClient.refetchQueries({ queryKey: orderQueryKey.all })
			queryClient.invalidateQueries({ queryKey: orderQueryKey.byGroupOrderId(groupOrderId) })
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.disburseOrder,
			})
		}
	})
}

export const useUpdateKitchenGroupOrderMutation = ({ successFn }) => {
	const errorLogger = useErrorLogger()
	const queryClient = useQueryClient()
	const { toast, getBusinessId } = useGlobalContext()
	
	return useMutation({
		mutationFn: (body) => {
			return updateKitchenGroupOrderService(getBusinessId(), body.id, body)
		},
		
		onSuccess: () => {
			successFn()
			toast.success("Order completed", "updated")
			queryClient.invalidateQueries({ queryKey: orderQueryKey.kitchenDisplay("") })
		},
		
		onError: (err, variables) => {
			const formatError = errorHandler(err)
			toast.error(formatError, formatError)
			
			errorLogger.create({
				error: err, payload: variables,
				errorType: errorTypes.updateKitchenGroupOrder,
			})
		}
	})
}


export {
	useGroupOrderByBusinessIdQuery, useSingleGroupByBusinessIdAndGroupIdQuery, useConfirmGroupOrderByIdMutation,
	useGroupOrdersByBusinessIdAndTableTrackIdQuery, useCreateOrderMutation, useGroupOrderByStaffIdQuery, useReturnSingleGroupOrderByGroupIdMutation,
	useCancelSingleGroupOrderByGroupIdMutation, usePayForGroupOrderMutation, useDisburseOrderMutation, usePartialPayForGroupOrderMutation
}