export const reportPermissions = {
	allReport: "REP001",
	salesDetailsReport: "REP002",
	salesSummaryReport: "REP003",
	groupSalesReport: "REP004",
	paymentReport: "REP005",
	expensesReport: "REP006",
	debtReport: "REP007",
	wipReport: "REP008",
	productTransferReport: "REP009",
	supplierBalanceSummaryReport: "REP010",
	supplierBalanceDetailsReport: "REP011",
	purchaseOrderReport: "REP012",
	customerSubscription: "REP013",
	productValuation: "REP014"
}

export default {
	NO_DISPLAY: "NO_DISPLAY",
	onlyAdmin: "ADMIN045231",
	
	// Customer
	createCustomer: "CUS001",
	updateCustomer: "CUS003",
	deleteCustomer: "CUS004",
	
	// Service / AddOn
	createService: "SER001",
	updateService: "SER003",
	deleteService: "SER004",
	
	// Bank
	createBank: "BAN001",
	updateBank: "BAN003",
	deleteBank: "BAN004",
	
	// Category
	createCategory: "CAT001",
	updateCategory: "CAT003",
	deleteCategory: "CAT004",
	
	// Discount
	createDiscount: "DIS001",
	updateDiscount: "DIS003",
	deleteDiscount: "DIS004",
	
	// Product
	createProduct: "PRO001",
	updateProduct: "PRO003",
	deleteProduct: "PRO004",
	distributeProduct: "PRO005",
	adjustProductSellingPrice: "PRO007",
	
	// Tax
	createTax: "TAX001",
	
	// Expense
	createExpense: "EXP001",
	approveExpenses: "EXP003",
	rejectExpenses: "EXP004",
	
	// Order
	createOrder: "ORD001",
	reprintReceipt: "RR001",
	confirmPaymentForOrder: "ORD003",
	cancelOrder: "ORD004",
	markOrderAsReturned: "ORD006",
	markItemAsDelivered: "0RD007",
	changeSalesPriceWhenMakingSales: "ORD008",
	addPaymentDateDuringSales: "ORD009",
	disburseItemFromStore: "ORD010",
	setPaymentDate: "PAD001",
	setPartialPayment: "PAP001",
	setSplitPayment: "SPP001",
	refundPayment: "ORD013",
	customOrderDate: "ORD011",
	
	// INVENTORY
	addNewStock: "INV001",
	acceptInventory: "INV003",
	deleteInventory: "INV004",
	
	// DESIGNATION
	createDesignation: "DES001",
	updateDesignation: "DES003",
	deleteDesignation: "DES004",
	
	// STAFF
	createStaff: "STA001",
	updateStaff: "STA003",
	deleteStaff: "STA004",
	
	// TABLE
	createTable: "TBL001",
	updateTable: "TBL003",
	deleteTable: "TBL004",
	
	// DELIVERY RATE
	createDeliveryRate: "DLR001",
	updateDeliveryRate: "DLR003",
	deleteDeliveryRate: "DLR004",
	
	// OTHERS
	setCreditPayment: "CRP001",
	setHomeDelivery: "HMD001",
	
	// WIP
	crateWip: "WIP001",
	updateWip: "WIP003",
	assignStaffToWip: "WIP005",
	wipCustomerCommission: "WIPC01",
	
	// BUSINESS SETTINGS
	setBusinessSettings: "BSS001",
	
	// SUPPLIER
	createSupplier: "SUP001",
	editSupplier: "SUP003",
	deleteSupplier: "SUP004",
	
	// CUSTOM PAYMENT METHOD
	createCustomPaymentMethod: "CPM0001",
	updateCustomPaymentMethod: "CPM0003",
	deleteCustomPaymentMethod: "CPM0004",
	
	// CUSTOMER SUBSCRIPTION
	createCustomerSubscription: "CSB001",
	updateCustomerSubscription: "CSB003",
	deleteCustomerSubscription: "CSB004",
	
	// KITCHEN SCHEDULE
	confirmKitchenScheduleFood: "KIT002",
	
	
	// INVOICE
	createInvoice: "INC001",
	viewInvoice: "INC002",
	modifyInvoice: "INC003",
	acceptInvoicePayment: "INC004",
	
	// Bad and Damage
	moveProductToBadAndDamage: "INV005",
	moveBadAndDamageBackToStock: "INV006",
	
	// purchase order
	createPurchaseOrder: "PUR001",
	payForPurchaseOrder: "PUR003",
	receivePurchaseOrder: "PUR004",
	updatePurchaseOrder: "PUR005",
	deletePurchaseOrder: "PUR007",
	recallPurchaseOrder: "PUR008",
	
	// TIP
	createTip: "TIP001",
	deleteTip: "TIP003",
	
	// RAW MATERIAL
	createRawMaterial: "RAW001",
	updateRawMaterial: "RAW003",
	deleteRawMaterial: "RAW004",
	
	// PRODUCTION
	startProduction: "PDT001",
	changeProductionStage: "PDT003",
	completeProduction: "PDT004",
	requestRawMaterial: "PDT005",
	approveOrRejectRawMaterial: "PDT006",
	
	// PRODUCTION STAGE
	createProductionStage: "PDS001",
	updateProductionStage: "PDS003",
	deleteProductionStage: "PDS004",
	
	staffAnalyticsPermission: "SAN001",
	
	
	// REPORT PERMISSIONS
	...reportPermissions
}


export const notificationPermissionCode = {
	adjust_stock: "NOT007",
	expenses: "NOT006",
	low_stock_level: "NOT003",
	modify_order: "NOT008",
	ovaloop_pay: "NOT001",
	ovaloop_settlement: "NOT009",
	package_expiry: "NOT004",
	product_expiry: "NOT002",
	product_transfer: "NOT005",
	pending_order: "NOT010"
}


export const notificationPermissionCodeName = {
	"NOT007": "Adjust Stock Notification",
	"NOT006": "Expenses Notification",
	"NOT003": "Low Stock Level Notification",
	"NOT008": "Modify Order Notification",
	"NOT001": "Ovaloop Pay Notification",
	"NOT009": "Ovaloop Settlement Notification",
	"NOT004": "Package Expiry Notification",
	"NOT002": "Product Expiry Notification",
	"NOT005": "Product Transfer Notification",
	"NOT010": "Pending Order Notification"
}